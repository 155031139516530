@charset "UTF-8";
/* reset */
/* main variables */
body { margin: 0px; padding: 0px; font-family: "Lato", sans-serif; background-color: #FFF; height: 100%; line-height: 1; position: relative; font-size: 16px !important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea { color: #333; font-family: "Lato", sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #D05278; -webkit-transition: all 0.3s linear; -moz-transition: all 0.3s linear; -ms-transition: all 0.3s linear; -o-transition: all 0.3s linear; transition: all 0.3s linear; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

ul, li { list-style: none; }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon/icomoon.eot?1cf6lm"); src: url("../fonts/icomoon/icomoon.eot?1cf6lm#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?1cf6lm") format("truetype"), url("../fonts/icomoon/icomoon.woff?1cf6lm") format("woff"), url("../fonts/icomoon/icomoon.svg?1cf6lm#icomoon") format("svg"); font-weight: normal; font-style: normal; font-display: block; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: never; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-google:before { content: "\e924"; color: #fff; }

.icon-info:before { content: "\e923"; color: #d05278; }

.icon-star:before { content: "\e91f"; }

.icon-step1:before { content: "\e920"; }

.icon-step2:before { content: "\e921"; color: #999; }

.icon-step3:before { content: "\e922"; color: #999; }

.icon-comment:before { content: "\e91a"; color: #999; }

.icon-date:before { content: "\e91b"; color: #999; }

.icon-eye:before { content: "\e91c"; color: #999; }

.icon-reply:before { content: "\e91d"; color: #d05278; }

.icon-user2:before { content: "\e91e"; color: #999; }

.icon-arrow-md:before { content: "\e919"; color: #d05278; }

.icon-arrow-sm:before { content: "\e918"; color: #bbb; }

.icon-arrow:before { content: "\e902"; color: #999; }

.icon-24:before { content: "\e900"; }

.icon-2:before { content: "\e901"; }

.icon-arrow-lg:before { content: "\e903"; color: #fff; }

.icon-cart:before { content: "\e904"; }

.icon-close:before { content: "\e905"; color: #fff; }

.icon-facebook:before { content: "\e906"; color: #999; }

.icon-heart:before { content: "\e907"; }

.icon-helpline:before { content: "\e908"; }

.icon-in:before { content: "\e909"; color: #999; }

.icon-insta:before { content: "\e90a"; color: #fff; }

.icon-mail:before { content: "\e90b"; color: #d05278; }

.icon-map-pin:before { content: "\e90c"; color: #d05278; }

.icon-map-pin-big:before { content: "\e90d"; }

.icon-natural:before { content: "\e90e"; }

.icon-organic:before { content: "\e90f"; }

.icon-phone:before { content: "\e910"; }

.icon-quality:before { content: "\e911"; }

.icon-search:before { content: "\e912"; }

.icon-shipping:before { content: "\e913"; }

.icon-smartphone:before { content: "\e914"; color: #d05278; }

.icon-twitter:before { content: "\e915"; color: #999; }

.icon-user:before { content: "\e916"; }

.icon-video:before { content: "\e917"; }

/* checkbox */
/* radio */
/* select */
.jq-checkbox { top: -1px; width: 13px; height: 13px; border: 1px solid #C3C3C3; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; background: #F6F6F6; box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); vertical-align: middle; cursor: pointer; }

.jq-checkbox.checked .jq-checkbox__div { width: 9px; height: 9px; margin: 2px 0 0 2px; background: #666; box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05); }

.jq-checkbox.disabled { opacity: 0.5; }

.jq-radio { width: 12px; height: 12px; border: 1px solid #C3C3C3; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; background: #F6F6F6; box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); -moz-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); -webkit-box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); vertical-align: middle; cursor: pointer; }

.jq-radio.checked .jq-radio__div { width: 6px; height: 6px; margin: 3px 0 0 3px; background: #666; }

.jq-radio.disabled { opacity: 0.5; }

.jq-file { width: 270px; box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); border-radius: 4px; }

.jq-file input { cursor: pointer; height: auto; line-height: 1em; }

.jq-file__name { -moz-box-sizing: border-box; box-sizing: border-box; width: 100%; height: 32px; padding: 0 80px 0 10px; color: #333; font: 14px/30px Arial, sans-serif; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; border: 1px solid #CCC; border-bottom-color: #B3B3B3; border-radius: 4px; box-shadow: inset 1px 1px #F1F1F1; background: #FFF; }

.focused .jq-file__name { border: 1px solid #5794BF; }

.jq-file__browse { position: absolute; top: 1px; right: 1px; padding: 0 10px; border-left: 1px solid #CCC; border-radius: 0 4px 4px 0; background: #F1F1F1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAIAAABi9+OQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABdJREFUeNpi+v//PxM2zMDAQEtxgAADAF2uTe9L4getAAAAAElFTkSuQmCC) repeat-x; box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1); color: #333; text-shadow: 1px 1px #FFF; font: 14px/30px Arial, sans-serif; }

.jq-file:hover .jq-file__browse { background-color: #E6E6E6; background-position: 0 -10px; }

.jq-file:active .jq-file__browse { background: #F5F5F5; box-shadow: inset 1px 1px 3px #DDD; }

.jq-file.disabled .jq-file__name { color: #888; }

.jq-file.disabled, .jq-file.disabled .jq-file__browse { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-selectbox { vertical-align: middle; cursor: pointer; width: 100%; }

.jq-selectbox__select { height: 40px; padding: 10px 40px 0 15px; border: 1px solid #C3C3C3; border-radius: 3px; background: #F5F5F5; box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0); font-size: 14px; line-height: 18px; color: #000; font-family: Arial, sans-serif; }

.jq-selectbox__select:hover { background: #F5F5F5; }

.jq-selectbox__select:active { background: #F5F5F5; }

.jq-selectbox.focused .jq-selectbox__select { background: #F5F5F5; }

.opened .jq-selectbox__select { border-color: #000; }

.jq-selectbox.disabled .jq-selectbox__select { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-selectbox__select-text { display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.jq-selectbox__trigger { position: absolute; top: 0; right: 0; width: 34px; height: 100%; }

.jq-selectbox__trigger-arrow { position: absolute; top: 14px; right: 12px; width: 0; height: 0; overflow: hidden; border-top: 5px solid #000; border-right: 5px solid transparent; border-left: 5px solid transparent; background: none; }

.jq-selectbox__dropdown { top: 40px; width: 100%; -moz-box-sizing: border-box; box-sizing: border-box; margin: 0; padding: 0; border: 1px solid #000; border-radius: 4px; background: #FFF; box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); -webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.1); }

.jq-selectbox__search { margin: 5px; }

.jq-selectbox__search input { -moz-box-sizing: border-box; box-sizing: border-box; width: 100%; margin: 0; padding: 5px 27px 6px 8px; outline: none; border: 1px solid #CCC; border-radius: 3px; background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%; box-shadow: inset 1px 1px #F1F1F1; color: #333; -webkit-appearance: textfield; }

.jq-selectbox__search input::-webkit-search-cancel-button, .jq-selectbox__search input::-webkit-search-decoration { -webkit-appearance: none; }

.jq-selectbox__not-found { margin: 5px; padding: 5px 8px 6px; background: #F0F0F0; font-size: 13px; }

.jq-selectbox ul { margin: 0; padding: 0; }

.jq-selectbox li { min-height: 18px; padding: 5px 10px 5px 10px; color: #000; font-size: 14px; line-height: 18px; font-family: Arial, sans-serif; }

.jq-selectbox li.selected { background-color: #000; color: #FFF; }

.jq-selectbox li:hover { background-color: #000; color: #FFF; }

.jq-selectbox li.disabled { color: #ccc; background: none; }

.jq-selectbox li.disabled:hover { background: none; }

.jq-selectbox li.optgroup { font-weight: bold; }

.jq-selectbox li.optgroup:hover { background: none; color: #231F20; cursor: default; }

.jq-selectbox li.option { padding-left: 25px; }

.jq-select-multiple { -moz-box-sizing: border-box; box-sizing: border-box; padding: 1px; border: 1px solid #CCC; border-bottom-color: #B3B3B3; border-radius: 4px; box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0, 0, 0, 0.1); background: #FFF; color: #333; font: 14px/18px Arial, sans-serif; cursor: default; }

.jq-select-multiple.focused { border: 1px solid #5794BF; }

.jq-select-multiple.disabled { border-color: #CCC; background: #F5F5F5; box-shadow: none; color: #888; }

.jq-select-multiple ul { margin: 0; padding: 0; }

.jq-select-multiple li { padding: 3px 9px 4px; list-style: none; }

.jq-select-multiple li:first-child { border-radius: 3px 3px 0 0; }

.jq-select-multiple li:last-child { border-radius: 0 0 3px 3px; }

.jq-select-multiple li.selected { background: #08C; color: #FFF; }

.jq-select-multiple li.disabled { color: #AAA; }

.jq-select-multiple.disabled li.selected, .jq-select-multiple li.selected.disabled { background: #CCC; color: #FFF; }

.jq-select-multiple li.optgroup { font-weight: bold; }

.jq-select-multiple li.option { padding-left: 25px; }

/*!Ion.RangeSlider, 2.3.1, © Denis Ineshin, 2010 - 2019, IonDen.com, Build date: 2019-12-19 16:51:02*/
.irs { position: relative; display: block; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; font-size: 12px; font-family: Arial,sans-serif; }

.irs-line { position: relative; display: block; overflow: hidden; outline: none !important; }

.irs-bar { position: absolute; display: block; left: 0; width: 0; }

.irs-shadow { position: absolute; display: none; left: 0; width: 0; }

.irs-handle { position: absolute; display: block; box-sizing: border-box; cursor: default; z-index: 1; }

.irs-handle.type_last { z-index: 2; }

.irs-min, .irs-max { position: absolute; display: block; cursor: default; }

.irs-min { left: 0; }

.irs-max { right: 0; }

.irs-from, .irs-to, .irs-single { position: absolute; display: block; top: 0; left: 0; cursor: default; white-space: nowrap; }

.irs-grid { position: absolute; display: none; bottom: 0; left: 0; width: 100%; height: 20px; }

.irs-with-grid .irs-grid { display: block; }

.irs-grid-pol { position: absolute; top: 0; left: 0; width: 1px; height: 8px; background: #000; }

.irs-grid-pol.small { height: 4px; }

.irs-grid-text { position: absolute; bottom: 0; left: 0; white-space: nowrap; text-align: center; font-size: 9px; line-height: 9px; padding: 0 3px; color: #000; }

.irs-disable-mask { position: absolute; display: block; top: 0; left: -1%; width: 102%; height: 100%; cursor: default; background: rgba(0, 0, 0, 0); z-index: 2; }

.lt-ie9 .irs-disable-mask { background: #000; filter: alpha(opacity=0); cursor: not-allowed; }

.irs-disabled { opacity: .4; }

.irs-hidden-input { position: absolute !important; display: block !important; top: 0 !important; left: 0 !important; width: 0 !important; height: 0 !important; font-size: 0 !important; line-height: 0 !important; padding: 0 !important; margin: 0 !important; overflow: hidden; outline: none !important; z-index: -9999 !important; background: none !important; border-style: solid !important; border-color: transparent !important; }

.irs--flat { height: 40px; }

.irs--flat.irs-with-grid { height: 60px; }

.irs--flat .irs-line { top: 25px; height: 12px; background-color: #e1e4e9; border-radius: 4px; }

.irs--flat .irs-bar { top: 25px; height: 12px; background-color: #ed5565; }

.irs--flat .irs-bar--single { border-radius: 4px 0 0 4px; }

.irs--flat .irs-shadow { height: 1px; bottom: 16px; background-color: #e1e4e9; }

.irs--flat .irs-handle { top: 22px; width: 16px; height: 18px; background-color: transparent; }

.irs--flat .irs-handle > i:first-child { position: absolute; display: block; top: 0; left: 50%; width: 2px; height: 100%; margin-left: -1px; background-color: #da4453; }

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child { background-color: #a43540; }

.irs--flat .irs-min, .irs--flat .irs-max { top: 0; padding: 1px 3px; color: #999; font-size: 10px; line-height: 1.333; text-shadow: none; background-color: #e1e4e9; border-radius: 4px; }

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single { color: white; font-size: 10px; line-height: 1.333; text-shadow: none; padding: 1px 5px; background-color: #ed5565; border-radius: 4px; }

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before { position: absolute; display: block; content: ""; bottom: -6px; left: 50%; width: 0; height: 0; margin-left: -3px; overflow: hidden; border: 3px solid transparent; border-top-color: #ed5565; }

.irs--flat .irs-grid-pol { background-color: #e1e4e9; }

.irs--flat .irs-grid-text { color: #999; }

.irs--big { height: 55px; }

.irs--big.irs-with-grid { height: 70px; }

.irs--big .irs-line { top: 33px; height: 12px; background-color: white; background: linear-gradient(to bottom, #ddd -50%, white 150%); border: 1px solid #ccc; border-radius: 12px; }

.irs--big .irs-bar { top: 33px; height: 12px; background-color: #92bce0; border: 1px solid #428bca; background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%); box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5); }

.irs--big .irs-bar--single { border-radius: 12px 0 0 12px; }

.irs--big .irs-shadow { height: 1px; bottom: 16px; background-color: rgba(66, 139, 202, 0.5); }

.irs--big .irs-handle { top: 25px; width: 30px; height: 30px; border: 1px solid rgba(0, 0, 0, 0.3); background-color: #cbcfd5; background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%); box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white; border-radius: 30px; }

.irs--big .irs-handle.state_hover, .irs--big .irs-handle:hover { border-color: rgba(0, 0, 0, 0.45); background-color: #939ba7; background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%); }

.irs--big .irs-min, .irs--big .irs-max { top: 0; padding: 1px 5px; color: white; text-shadow: none; background-color: #9f9f9f; border-radius: 3px; }

.irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single { color: white; text-shadow: none; padding: 1px 5px; background-color: #428bca; background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%); border-radius: 3px; }

.irs--big .irs-grid-pol { background-color: #428bca; }

.irs--big .irs-grid-text { color: #428bca; }

.irs--modern { height: 55px; }

.irs--modern.irs-with-grid { height: 55px; }

.irs--modern .irs-line { top: 25px; height: 5px; background-color: #d1d6e0; background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%); border: 1px solid #a3adc1; border-bottom-width: 0; border-radius: 5px; }

.irs--modern .irs-bar { top: 25px; height: 5px; background: #20b426; background: linear-gradient(to bottom, #20b426 0%, #18891d 100%); }

.irs--modern .irs-bar--single { border-radius: 5px 0 0 5px; }

.irs--modern .irs-shadow { height: 1px; bottom: 21px; background-color: rgba(209, 214, 224, 0.5); }

.irs--modern .irs-handle { top: 37px; width: 12px; height: 13px; border: 1px solid #a3adc1; border-top-width: 0; box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(1) { position: absolute; display: block; top: -4px; left: 1px; width: 6px; height: 6px; border: 1px solid #a3adc1; background: white; transform: rotate(45deg); }

.irs--modern .irs-handle > i:nth-child(2) { position: absolute; display: block; box-sizing: border-box; top: 0; left: 0; width: 10px; height: 12px; background: #e9e6e6; background: linear-gradient(to bottom, white 0%, #e9e6e6 100%); border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(3) { position: absolute; display: block; box-sizing: border-box; top: 3px; left: 3px; width: 4px; height: 5px; border-left: 1px solid #a3adc1; border-right: 1px solid #a3adc1; }

.irs--modern .irs-handle.state_hover, .irs--modern .irs-handle:hover { border-color: #7685a2; background: #c3c7cd; background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%); }

.irs--modern .irs-handle.state_hover > i:nth-child(1), .irs--modern .irs-handle:hover > i:nth-child(1) { border-color: #7685a2; }

.irs--modern .irs-handle.state_hover > i:nth-child(3), .irs--modern .irs-handle:hover > i:nth-child(3) { border-color: #48536a; }

.irs--modern .irs-min, .irs--modern .irs-max { top: 0; font-size: 10px; line-height: 1.333; text-shadow: none; padding: 1px 5px; color: white; background-color: #d1d6e0; border-radius: 5px; }

.irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single { font-size: 10px; line-height: 1.333; text-shadow: none; padding: 1px 5px; background-color: #20b426; color: white; border-radius: 5px; }

.irs--modern .irs-from:before, .irs--modern .irs-to:before, .irs--modern .irs-single:before { position: absolute; display: block; content: ""; bottom: -6px; left: 50%; width: 0; height: 0; margin-left: -3px; overflow: hidden; border: 3px solid transparent; border-top-color: #20b426; }

.irs--modern .irs-grid { height: 25px; }

.irs--modern .irs-grid-pol { background-color: #dedede; }

.irs--modern .irs-grid-text { color: silver; font-size: 13px; }

.irs--sharp { height: 50px; font-size: 12px; line-height: 1; }

.irs--sharp.irs-with-grid { height: 57px; }

.irs--sharp .irs-line { top: 30px; height: 2px; background-color: black; border-radius: 2px; }

.irs--sharp .irs-bar { top: 30px; height: 2px; background-color: #ee22fa; }

.irs--sharp .irs-bar--single { border-radius: 2px 0 0 2px; }

.irs--sharp .irs-shadow { height: 1px; bottom: 21px; background-color: rgba(0, 0, 0, 0.5); }

.irs--sharp .irs-handle { top: 25px; width: 10px; height: 10px; background-color: #a804b2; }

.irs--sharp .irs-handle > i:first-child { position: absolute; display: block; top: 100%; left: 0; width: 0; height: 0; border: 5px solid transparent; border-top-color: #a804b2; }

.irs--sharp .irs-handle.state_hover, .irs--sharp .irs-handle:hover { background-color: black; }

.irs--sharp .irs-handle.state_hover > i:first-child, .irs--sharp .irs-handle:hover > i:first-child { border-top-color: black; }

.irs--sharp .irs-min, .irs--sharp .irs-max { color: white; font-size: 14px; line-height: 1; top: 0; padding: 3px 4px; opacity: .4; background-color: #a804b2; border-radius: 2px; }

.irs--sharp .irs-from, .irs--sharp .irs-to, .irs--sharp .irs-single { font-size: 14px; line-height: 1; text-shadow: none; padding: 3px 4px; background-color: #a804b2; color: white; border-radius: 2px; }

.irs--sharp .irs-from:before, .irs--sharp .irs-to:before, .irs--sharp .irs-single:before { position: absolute; display: block; content: ""; bottom: -6px; left: 50%; width: 0; height: 0; margin-left: -3px; overflow: hidden; border: 3px solid transparent; border-top-color: #a804b2; }

.irs--sharp .irs-grid { height: 25px; }

.irs--sharp .irs-grid-pol { background-color: #dedede; }

.irs--sharp .irs-grid-text { color: silver; font-size: 13px; }

.irs--round { height: 50px; }

.irs--round.irs-with-grid { height: 65px; }

.irs--round .irs-line { top: 36px; height: 4px; background-color: #dee4ec; border-radius: 4px; }

.irs--round .irs-bar { top: 36px; height: 4px; background-color: #006cfa; }

.irs--round .irs-bar--single { border-radius: 4px 0 0 4px; }

.irs--round .irs-shadow { height: 4px; bottom: 21px; background-color: rgba(222, 228, 236, 0.5); }

.irs--round .irs-handle { top: 26px; width: 24px; height: 24px; border: 4px solid #006cfa; background-color: white; border-radius: 24px; box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3); }

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover { background-color: #f0f6ff; }

.irs--round .irs-min, .irs--round .irs-max { color: #333; font-size: 14px; line-height: 1; top: 0; padding: 3px 5px; background-color: rgba(0, 0, 0, 0.1); border-radius: 4px; }

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single { font-size: 14px; line-height: 1; text-shadow: none; padding: 3px 5px; background-color: #006cfa; color: white; border-radius: 4px; }

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before { position: absolute; display: block; content: ""; bottom: -6px; left: 50%; width: 0; height: 0; margin-left: -3px; overflow: hidden; border: 3px solid transparent; border-top-color: #006cfa; }

.irs--round .irs-grid { height: 25px; }

.irs--round .irs-grid-pol { background-color: #dedede; }

.irs--round .irs-grid-text { color: silver; font-size: 13px; }

.irs--square { height: 50px; }

.irs--square.irs-with-grid { height: 60px; }

.irs--square .irs-line { top: 31px; height: 4px; background-color: #dedede; }

.irs--square .irs-bar { top: 31px; height: 4px; background-color: black; }

.irs--square .irs-shadow { height: 2px; bottom: 21px; background-color: #dedede; }

.irs--square .irs-handle { top: 25px; width: 16px; height: 16px; border: 3px solid black; background-color: white; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }

.irs--square .irs-handle.state_hover, .irs--square .irs-handle:hover { background-color: #f0f6ff; }

.irs--square .irs-min, .irs--square .irs-max { color: #333; font-size: 14px; line-height: 1; top: 0; padding: 3px 5px; background-color: rgba(0, 0, 0, 0.1); }

.irs--square .irs-from, .irs--square .irs-to, .irs--square .irs-single { font-size: 14px; line-height: 1; text-shadow: none; padding: 3px 5px; background-color: black; color: white; }

.irs--square .irs-grid { height: 25px; }

.irs--square .irs-grid-pol { background-color: #dedede; }

.irs--square .irs-grid-text { color: silver; font-size: 11px; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header { width: 100%; min-width: 320px; text-align: left; order: 1; -webkit-order: 1; }

.header { position: absolute; left: 0; top: 0; width: 100%; z-index: 101; }

.header-top { background-color: #222222; padding: 13px 0; position: absolute; top: 0; left: 0; width: 100%; text-align: center; }

.header-top span { font-weight: bold; font-size: 14px; color: #FFFFFF; opacity: 0.8; text-transform: uppercase; margin-left: 60px; }

.header-top-close { position: absolute; right: 14px; top: 50%; transform: translateY(-50%); cursor: pointer; transition: all 0.3s linear; font-size: 13px; }

.header-top-close:hover { opacity: .8; }

.header-content { padding: 72px 70px 0; padding-right: 72px; display: flex; justify-content: space-between; align-items: center; transition: all 0.3s linear; }

.header-content.fixed { position: fixed; width: 100%; padding-top: 20px; padding-bottom: 20px; background-color: rgba(252, 237, 234, 0.9); }

.header-box { display: flex; align-items: center; width: calc(100% - 160px); justify-content: space-between; }

.header-nav { display: flex; margin-right: auto; }

.header-nav li { margin: 0 30px; position: relative; }

.header-nav li a { color: #222222; font-size: 14px; line-height: 17px; position: relative; text-transform: uppercase; z-index: 2; }

.header-nav li a:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 30px; height: 37px; opacity: 0; background-image: url(../img/link-bg.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; }

.header-nav li a:hover, .header-nav li a.active { color: #D05278; }

.header-nav li a:hover:after, .header-nav li a.active:after { opacity: 1; }

.header-nav li ul { position: absolute; top: 47px; left: 200%; transform: translateX(-50%); width: 264px; background: #FFFFFF; padding: 25px 0; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); opacity: 0; visibility: hidden; }

.header-nav li ul li { padding: 0; margin: 0; }

.header-nav li ul li a { text-transform: initial; font-size: 16px; line-height: 170%; color: #666666; padding: 7.6px 40px; display: block; }

.header-nav li ul li a:hover { background-color: #D05278; color: #FFFFFF; }

.header-nav li ul li a:hover:after { display: none; }

.header-nav li:hover:after { position: absolute; left: 0; top: 0; content: ''; width: 100%; height: 80px; z-index: 1; }

.header-nav li:hover ul { opacity: 1; visibility: visible; }

.header-options { display: flex; align-items: center; }

.header-options li { margin-left: 45px; }

.header-options li a { display: flex; align-items: center; }

.header-options li i { color: #222222; transition: all 0.3s linear; font-size: 19px; }

.header-options li i:hover { color: #D05278; }

.header-options li span { width: 22px; height: 22px; border-radius: 50%; display: flex; justify-content: center; align-items: center; background: #D05278; font-family: 'Montserrat'; font-weight: 700; font-size: 12px; color: #fff; }

.btn-menu { color: #222222; cursor: pointer; display: none; height: 45px; width: 22px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: absolute; top: 72%; transform: translateY(-50%); right: 15px; z-index: 111; }

.btn-menu span { background-color: #222222; display: block; height: 2px; left: calc(50% - 11px); position: absolute; -ms-transform-origin: center; transform-origin: center; transition-duration: 86ms; transition-property: background-color,opacity,transform; transition-timing-function: ease-out; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); width: 22px; }

.btn-menu span:nth-child(1) { top: calc(50% - 7px); }

.btn-menu span:nth-child(2) { top: calc(50% - 1px); }

.btn-menu span:nth-child(3) { top: calc(50% + 5px); }

.btn-menu.active { cursor: pointer; }

.btn-menu.active span:nth-child(1) { transform: translateY(5px) rotate(45deg); }

.btn-menu.active span:nth-child(2) { opacity: 0; }

.btn-menu.active span:nth-child(3) { transform: translateY(-7px) rotate(-45deg); }

@media screen and (max-width: 1600px) { .header-logo { min-width: 110px; }
  .header-content { padding: 72px 40px 0; } }

@media screen and (max-width: 1200px) { .header-logo { min-width: 85px; }
  .header-nav li { margin: 0 15px; }
  .header-nav li a, .header-nav li ul li a { font-size: 12px; }
  .header-box { width: calc(100% - 125px); }
  .header-options li { margin-left: 25px; }
  .header-nav li ul li a { padding: 5px 30px; }
  .header-nav li ul { padding: 10px 0; width: 220px; } }

@media screen and (max-width: 991px) { .header-options li i { font-size: 16px; }
  .header-options li { margin-left: 15px; }
  .header-options li span { width: 18px; height: 18px; font-size: 11px; }
  .header-nav li { margin: 0 10px; }
  .header-top span { font-size: 12px; margin-left: 0; }
  .header-content { padding: 55px 20px 0; } }

@media screen and (max-width: 767px) { .btn-menu { display: block; }
  .header-content { padding: 45px 15px 0; }
  .header-top span { font-size: 9px; display: block; }
  .header-top-close { top: 48%; }
  .header-logo { width: 85px; }
  .header-box { right: -306px; position: fixed; top: 0; width: 230px; height: 100%; flex-direction: column; background-color: #ffffff; color: #2a2a2a; z-index: 101; justify-content: start; align-items: flex-start; padding: 48px 30px 30px; transition: .3s ease-in; overflow: scroll; flex-direction: column; }
  .header-box.active { right: 0; }
  .header-nav { flex-direction: column; }
  .header-nav li { margin: 0; margin-bottom: 15px; }
  .header-nav li a, .header-nav li ul li a { font-size: 14px; }
  .header-options li { margin-left: 0; }
  .header-options { margin-top: 20px; padding-top: 20px; border-top: 1px solid #000; width: 100%; display: flex; justify-content: space-between; }
  .header-content.fixed { padding-top: 20px; padding-bottom: 10px; }
  .header-content.fixed .btn-menu { top: 50%; }
  .header-nav li ul { width: 100%; position: relative; left: auto; transform: none; top: auto; box-shadow: none; opacity: 1; visibility: visible; display: none; }
  .header-nav li ul.active { display: block; }
  .header-nav li ul li a { padding-left: 10px; padding-right: 10px; }
  .header-nav li:hover:after { display: none; } }

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer { text-align: left; width: 100%; min-width: 320px; order: 3; -webkit-order: 3; }

.footer { background: #222222; z-index: 1; }

.footer-top { padding: 60px 0 57px; display: flex; justify-content: space-between; border-bottom: 1px solid rgba(255, 255, 255, 0.1); }

.footer-top__social span { font-size: 16px; line-height: 170%; color: #999999; display: block; margin-bottom: 8px; }

.footer-top__social ul { display: flex; }

.footer-top__social ul li { margin-right: 10px; }

.footer-top__social ul li a { width: 35px; height: 35px; display: flex; justify-content: center; align-items: center; background: rgba(255, 255, 255, 0.1); }

.footer-top__social ul li a i:before { color: #999999; transition: all 0.3s linear; }

.footer-top__social ul li a:hover i:before { color: #fff; }

.footer-top__logo { margin-left: 80px; margin-top: 10px; }

.footer-top__payments { padding-right: 16px; }

.footer-top__payments span { font-size: 16px; line-height: 170%; color: #999999; display: block; margin-bottom: 13px; }

.footer-top__payments ul { display: flex; }

.footer-top__payments ul li { margin-right: 11.5px; }

.footer-top__payments ul li a:hover { opacity: .8; }

.footer-nav { padding: 60px 0 63px; border-bottom: 1px solid rgba(255, 255, 255, 0.1); display: flex; justify-content: space-between; }

.footer-nav__col { max-width: 215px; width: 25%; }

.footer-nav__col-title { display: block; font-size: 24px; line-height: 100%; text-transform: capitalize; color: #FFFFFF; margin-bottom: 24px; font-family: 'Tenor Sans'; }

.footer-nav__col ul li { margin-bottom: 8px; }

.footer-nav__col ul li:last-of-type { margin-bottom: 0; }

.footer-nav__col ul li a { color: #BBBBBB; font-size: 16px; line-height: 170%; position: relative; padding-left: 15px; }

.footer-nav__col ul li a:hover { color: #fff; }

.footer-nav__col ul li a:before { position: absolute; left: 0; top: 50%; transform: translateY(-50%); font-family: 'icomoon'; content: "\e918"; color: #bbb; font-size: 8px; }

@-moz-document url-prefix() { .footer-nav__col ul li a:before { top: 60%; } }

.footer-nav__col:last-of-type { margin-right: 50px; }

.footer-nav__col:last-of-type ul li { display: flex; color: #BBBBBB; line-height: 170%; margin-bottom: 19px; }

.footer-nav__col:last-of-type ul li i { margin-right: 15px; padding-top: 4px; font-size: 21px; }

.footer-nav__col:last-of-type ul li a { padding-left: 0; }

.footer-nav__col:last-of-type ul li a:before { display: none; }

.footer-nav__col:last-of-type ul li .footer-nav__col-phones a { display: block; color: #FFFFFF; font-size: 20px; margin-bottom: 8px; line-height: normal; }

.footer-nav__col:last-of-type ul li .footer-nav__col-phones a:hover { opacity: .8; }

.footer-copy { padding-top: 16px; padding-bottom: 20px; font-size: 14px; line-height: 170%; color: #999999; text-align: center; }

@media screen and (max-width: 991px) { .footer-top__payments { padding-right: 0; }
  .footer-top__logo { margin-left: 0; }
  .footer-top__payments ul { width: 100%; margin-left: -6px; margin-right: -6px; display: flex; flex-wrap: wrap; }
  .footer-top__payments ul li { width: calc(50% - 12px); margin: 0 6px; margin-bottom: 5px; }
  .footer-top__payments ul li a { width: 100%; }
  .footer-top__payments ul li a img { width: 100%; }
  .footer-top__payments { max-width: 145px; }
  .footer-top { padding: 40px 0 40px; }
  .footer-nav__col:last-of-type { margin-right: 0; }
  .footer-nav__col:last-of-type ul li .footer-nav__col-phones a { font-size: 16px; }
  .footer-nav__col:last-of-type ul li i { margin-right: 15px; padding-top: 6px; font-size: 16px; }
  .footer-nav { padding: 40px 0 40px; }
  .footer-nav__col-title { font-size: 20px; }
  .footer-nav__col ul li a, .footer-copy { font-size: 15px; }
  .footer-nav__col:last-of-type ul li .footer-nav__col-phones a { font-size: 15px; } }

@media screen and (max-width: 700px) { .footer-top { flex-wrap: wrap; justify-content: center; text-align: center; flex-direction: column; }
  .footer-top__logo { order: 1; margin-top: 0; }
  .footer-top__social { order: 2; margin: 10px 0; }
  .footer-top__payments { order: 3; max-width: 100%; }
  .footer-top__social ul { justify-content: center; }
  .footer-top__payments ul { margin-left: 0; margin-right: 0; justify-content: center; }
  .footer-top__payments ul li { width: auto; margin-bottom: 0; }
  .footer-nav { flex-wrap: wrap; }
  .footer-nav__col { max-width: 100%; width: 45%; margin-bottom: 25px; }
  .footer-nav__col:last-of-type { margin-bottom: 0; }
  .footer-nav__col:last-of-type ul li:last-of-type { margin-bottom: 0; } }

@media screen and (max-width: 374px) { .footer-nav__col { width: 100%; }
  .footer-nav__col-title { text-align: center; width: 100%; } }

.main-wrapper { padding: 0 0 0 0; min-width: 320px; width: 100%; position: relative; overflow: hidden; min-height: 100%; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-direction: column; -webkit-flex-direction: column; -ms-flex-direction: column; justify-content: flex-start; -webkit-justify-content: flex-start; -ms-flex-pack: start; }

.wrapper { min-width: 320px; max-width: 1220px; padding: 0 40px 0 40px; margin: 0 auto; position: relative; }

/* titles */
p { font-size: 16px; line-height: 18px; color: #666666; font-weight: 400; line-height: 170%; }

h1 { font-size: 70px; line-height: 70px; color: #222222; font-family: 'Tenor Sans'; text-transform: capitalize; font-weight: 400; }

h2 { font-size: 54px; line-height: 54px; color: #222222; font-family: 'Tenor Sans'; text-transform: capitalize; font-weight: 400; }

h3 { font-size: 40px; line-height: 56px; color: #222222; font-weight: 400; font-family: 'Tenor Sans'; text-transform: capitalize; }

h4 { font-size: 32px; line-height: 130%; font-family: 'Tenor Sans'; text-transform: capitalize; font-weight: 400; }

h5, .title5 { font-size: 24px; line-height: 100%; font-family: 'Tenor Sans'; text-transform: capitalize; font-weight: 400; color: #222222; }

h6, .title6 { font-size: 20px; line-height: 120%; font-family: 'Tenor Sans'; text-transform: capitalize; font-weight: 400; color: #222222; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

/* loader */
.loaded .main-wrapper { visibility: hidden; opacity: 0; }

.icon-load { background: url(../img/loader.gif) no-repeat left top; width: 40px; height: 40px; position: fixed; left: 50%; top: 50%; margin-left: -20px; margin-left: -20px; display: none; }

.loaded .icon-load { display: block; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { min-width: 320px; text-align: left; width: 100%; order: 2; -webkit-order: 2; flex-grow: 1; -webkit-flex-grow: 1; }

.btn { height: 60px; line-height: 59px; outline: none; border: none; background: #222222; padding: 0 50px; font-weight: bold; font-size: 14px; color: #FFFFFF; text-transform: uppercase; display: inline-block; transition: all 0.3s linear; font-family: 'Lato'; }

.btn:hover { background: #545454; }

.btn-icon { display: flex; align-items: center; }

.btn-icon i { margin-right: 12px; font-size: 17px; }

.btn-grey { background: #FAF9FF; border: 1px solid #EEEEEE; color: #222222; }

.btn-grey:hover { color: #eee; background: #222222; border-color: #222222; }

.saint-text { font-family: 'Mrs Saint Delafield'; color: #D05278; display: block; font-size: 50px; text-transform: capitalize; }

.main-text { display: block; font-family: 'Tenor Sans'; color: #222222; font-size: 100px; margin-bottom: 30px; margin-top: 4px; }

.main-text span { color: #D05278; position: relative; }

.main-text span:before { content: ''; position: absolute; left: 45%; top: 50%; transform: translate(-50%, -50%); width: 181px; height: 155px; background-image: url(../img/main-text-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; }

.main-block { padding-top: 340px; padding-bottom: 237px; background-image: url(../img/main-bg.jpg); background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; z-index: 1; }

.main-block__content { margin-left: -8px; }

.main-block__decor { position: absolute; left: 0; top: 36%; width: 42.4%; z-index: -1; }

.main-block .saint-text { font-size: 60px; padding-left: 9px; }

.main-block .main-text { line-height: normal; }

.main-block p { max-width: 465px; font-size: 20px; line-height: 150%; font-family: 'Tenor Sans'; padding-left: 10px; }

.main-block .btn { margin-top: 60px; margin-left: 10px; }

.box-tab-cont { position: relative; }

.hide { position: absolute; left: 0; top: 0; height: 0; width: 0; overflow: hidden; z-index: -1; opacity: 0; }

.trending { padding-top: 180px; padding-bottom: 130px; }

.trending-top { text-align: center; margin-bottom: 59px; }

.trending-top p { max-width: 456px; margin: 0 auto; margin-top: 29px; }

.trending-tabs .tabs { justify-content: center; display: flex; margin-bottom: 40px; flex-wrap: wrap; }

.trending-tabs li { margin: 0 7.1px; }

.trending-tabs li a { padding: 0 30px; height: 41px; line-height: 40px; background: #FAF9FF; border: 1px solid #EEEEEE; font-size: 16px; color: #666666; display: block; }

.trending-tabs li a:hover { opacity: .8; }

@-moz-document url-prefix() { .trending-tabs li a { line-height: 38px; } }

.trending-tabs li.active a { background: #D05278; color: #FFFFFF; }

.products-items { padding: 0 115px; margin-left: -22px; margin-right: -22px; }

.products-item { margin: 0 15px; width: calc(25% - 30px); position: relative; display: block; }

.products-item__img { position: relative; height: 570px; width: 100%; }

.products-item__img img { width: 100%; height: 100%; object-fit: cover; }

.products-item__hover { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #222222; background-color: rgba(34, 34, 34, 0.15); padding: 30px; display: flex; align-items: flex-end; justify-content: center; opacity: 0; transition: all 0.3s linear; }

.products-item__hover .icon-search { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); font-size: 70px; color: #fff; }

.products-item__hover-options { display: flex; }

.products-item__hover-options i { width: 60px; height: 60px; border-radius: 50%; margin: 0 10px; display: flex; align-items: center; justify-content: center; background: #FFFFFF; box-shadow: 0px 4px 10px rgba(34, 34, 34, 0.15); color: #222222; cursor: pointer; transition: all 0.3s linear; font-size: 20px; }

.products-item__hover-options i.icon-cart { background: #D05278; color: #fff; }

.products-item__hover-options i:hover { opacity: .8; }

.products-item__hover-options i.active { background: #D05278; color: #fff; }

.products-item:hover .products-item__hover { opacity: 1; }

.products-item__type { position: absolute; right: 0; top: 0; display: flex; flex-direction: column; }

.products-item__sale, .products-item__new { background: #A3D2A2; padding: 10px 25px; text-transform: uppercase; color: #FFFFFF; font-weight: bold; font-size: 14px; line-height: 100%; z-index: 1; }

.products-item__new { background: rgba(208, 82, 120, 0.7); }

.products-item__info { text-align: center; margin-top: 36px; }

.products-item__name { font-size: 24px; text-transform: capitalize; color: #222222; display: block; margin-bottom: 9px; font-family: 'Tenor Sans'; padding: 0 3px; }

.products-item__cost { font-weight: 700; font-size: 18px; line-height: 170%; color: #666666; display: flex; align-items: center; justify-content: center; }

.products-item__cost span { color: #999999; font-size: 16px; line-height: 170%; text-decoration-line: line-through; margin-right: 10px; font-weight: 400; }

.slick-arrow { position: absolute; top: 45.5%; transform: translateY(-50%); left: 58px; background: #FAF9FF; border: 1px solid #EEEEEE; height: 60px; width: 40px; display: flex; align-items: center; justify-content: center; transition: all 0.3s linear; }

.slick-arrow i { font-size: 20px; }

.slick-arrow i:before { color: #999999; }

.slick-arrow:hover { background: #222222; border: 1px solid #222222; }

.slick-arrow:hover i:before { color: #fff; }

.slick-arrow.slick-next { right: 58px; left: auto; }

.slick-arrow.slick-next i { transform: rotate(180deg); }

.slick-arrow.slick-disabled { pointer-events: none; opacity: .5; }

.main-logos { display: flex; justify-content: center; align-items: center; padding-bottom: 130px; }

.main-logos img { margin: 0 56px; }

.discount { padding: 214px 0; background-position: center; background-repeat: no-repeat; background-size: cover; }

.discount .wrapper { display: flex; justify-content: flex-end; }

.discount-info { max-width: 465px; margin-right: 84px; }

.discount-info .saint-text { font-size: 60px; padding-left: 9px; }

.discount-info .main-text { text-transform: capitalize; }

.discount-info p { font-size: 20px; line-height: 150%; font-family: 'Tenor Sans'; padding-left: 10px; }

.discount-info p.discount-info__sm { font-size: 16px; line-height: 170%; font-family: 'Lato'; }

.discount-info .btn { margin-top: 60px; margin-left: 10px; }

.advantages { padding-top: 190px; }

.advantages-items { display: flex; justify-content: center; margin-left: -12px; margin-right: -12px; }

.advantages-item { margin: 0 45px; width: calc(33.3% - 90px); text-align: center; }

.advantages-item__icon { margin-bottom: 55px; }

.advantages-item__icon i { position: relative; font-size: 70px; }

.advantages-item__icon i:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 110.52px; height: 94.85px; background-image: url(../img/main-text-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; }

.advantages-item h4 { margin-bottom: 6px; }

.top-categories { padding-top: 184px; }

.top-categories__text { max-width: 456px; text-align: center; margin: 0 auto; margin-bottom: 56px; padding-left: 10px; }

.top-categories__text p { margin-top: 30px; }

.top-categories__items { display: flex; margin-left: -15px; margin-right: -15px; }

.top-categories__item { width: calc(33.3% - 30px); margin: 0 15px; position: relative; height: 700px; }

.top-categories__item img { width: 100%; height: 100%; object-fit: cover; }

.top-categories__item-hover { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: rgba(34, 34, 34, 0.3); transition: all 0.3s linear; text-align: center; flex-direction: column; opacity: 0; }

.top-categories__item-hover span { display: block; font-size: 14px; line-height: 170%; text-transform: uppercase; color: #fff; }

.top-categories__item-hover i { font-size: 50px; margin-top: -15px; }

.top-categories__item-hover h5 { position: relative; margin-bottom: 51px; z-index: 1; margin-top: 44px; }

.top-categories__item-hover h5:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 166px; height: 66px; background-image: url(../img/top-categories-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; z-index: -1; }

.top-categories__item:hover .top-categories__item-hover { opacity: 1; }

.info-blocks { padding-top: 30px; }

.info-blocks__item { display: flex; justify-content: space-between; position: relative; background-repeat: no-repeat; background-position: right bottom; background-color: #FAF9FF; }

.info-blocks__item .wrapper { position: initial; display: flex; justify-content: flex-end; width: 100%; align-items: flex-end; }

.info-blocks__item-reverse { flex-direction: row-reverse; background-position: left bottom; background-color: #FCEDEA; }

.info-blocks__item-reverse .info-blocks__item-text { padding: 237px 10px 223px 0; }

.info-blocks__item-reverse .info-blocks__item-img { left: auto; right: 0; }

.info-blocks__item-reverse .wrapper { justify-content: flex-start; }

.info-blocks__item-img { width: 50%; position: absolute; top: 0; left: 0; height: 100%; display: flex; }

.info-blocks__item-img img { width: 100%; height: 100%; object-fit: cover; }

.info-blocks__item-img-overlay { position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: rgba(34, 34, 34, 0.3); display: flex; justify-content: center; align-items: center; text-align: center; flex-direction: column; z-index: 10; }

.info-blocks__item-img-overlay span { font-size: 40px; line-height: 140%; font-family: 'Tenor Sans'; display: block; color: #fff; margin-bottom: 45px; text-transform: capitalize; margin-top: -101px; }

.info-blocks__item-img-play { cursor: pointer; width: 150px; height: 150px; position: relative; transition: all 0.3s linear; }

.info-blocks__item-img-play:after { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: calc(100% + 25px); height: calc(100% + 25px); border: 3px solid #FFFFFF; filter: blur(5px); content: ''; border-radius: 50%; }

.info-blocks__item-img-play:hover { opacity: .8; }

.info-blocks__item-text { width: 50%; padding: 186px 116px; padding-right: 0; }

.info-blocks__item-text h2, .info-blocks__item-text p { max-width: 456px; }

.info-blocks__item-text p { display: block; font-size: 16px; line-height: 170%; color: #666666; margin-top: 24px; }

.info-blocks__item-text .btn { margin-top: 60px; }

.info-blocks__item-descr { font-size: 20px; line-height: 150%; color: #666666; display: block; max-width: 456px; font-family: 'Tenor Sans'; margin-top: 31px; }

.info-blocks__item-link { font-weight: bold; font-size: 14px; line-height: 100%; text-transform: uppercase; display: flex; align-items: center; color: #222222; margin-top: 35px; }

.info-blocks__item-link:hover { opacity: .8; }

.info-blocks__item-link .icon-video { font-size: 24px; margin-right: 20px; }

.info-blocks__item-link .icon-arrow-lg { font-size: 51px; margin-left: 19px; transition: all 0.3s linear; }

.info-blocks__item-link .icon-arrow-lg:before { color: #222222; }

.info-blocks__item-link:hover .icon-arrow-lg { margin-left: 25px; }

.info-blocks__item iframe { z-index: 2; position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

.arrivals { padding-top: 188px; }

.arrivals .trending-top { margin-bottom: 58px; padding-left: 10px; }

.latest-news { padding-top: 184px; }

.latest-news__btn { text-align: center; }

.latest-news .trending-top { padding-left: 10px; }

.latest-news .blog-items { margin-bottom: 0; }

.blog-items { display: flex; margin-left: -15px; margin-right: -15px; flex-wrap: wrap; }

.blog-item { width: calc(50% - 30px); margin: 0 15px; margin-bottom: 60px; }

.blog-item__img { height: 300px; position: relative; display: block; transition: all 0.3s linear; }

.blog-item__img img { width: 100%; height: 100%; object-fit: cover; }

.blog-item__img:hover { opacity: .8; }

.blog-item__date { position: absolute; top: 23px; right: 35px; z-index: 1; font-weight: bold; color: #222222; font-size: 24px; line-height: 24px; text-align: center; }

.blog-item__date:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 77px; height: 66px; background-image: url(../img/blog-item__date-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; z-index: -1; }

.blog-item__date span { font-size: 14px; line-height: 100%; display: block; }

.blog-item__title { display: block; margin-top: 40px; font-size: 24px; line-height: 26px; color: #222222; font-family: 'Tenor Sans'; margin-bottom: 15px; }

.blog-item__title:hover { color: #D05278; }

.blog-item p { margin-bottom: 25px; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; width: 90%; }

.blog-item__link { display: flex; align-items: center; font-size: 14px; line-height: 170%; text-transform: uppercase; width: max-content; }

.blog-item__link i { font-size: 8px; margin-left: 10px; transition: all 0.3s linear; margin-top: 1px; }

.blog-item__link:hover { opacity: .8; }

.blog-item__link:hover i { margin-left: 15px; }

.subscribe { margin-top: 180px; }

.subscribe-form { background-color: #FCEDEA; display: flex; align-items: center; padding: 0 87px 0 60px; overflow: hidden; }

.subscribe-form__img { position: relative; z-index: 1; padding-top: 8px; margin-right: 29px; }

.subscribe-form__img img { min-height: 50px; position: relative; display: block; }

.subscribe-form__img:after { content: ''; position: absolute; left: 47%; top: 50%; transform: translate(-50%, -50%); width: 524px; height: 450px; background-image: url(../img/subscribe-img-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; z-index: -1; }

.subscribe-form form { z-index: 1; margin-top: -5px; width: calc(100% - 425px); }

.subscribe-form .box-field__row { margin-top: 30px; justify-content: space-between; }

.subscribe-form .box-field__row .box-field { width: calc(100% - 186px); }

.box-field__row { display: flex; }

.box-field .form-control { background: #FFFFFF; border: 1px solid #FCEDEA; height: 60px; line-height: 60px; padding: 18px 29px; font-size: 14px; width: 100%; }

.box-field .form-control::placeholder { color: #999999; opacity: 1; }

.box-field .form-control:-ms-input-placeholder { color: #999999; }

.box-field .form-control::-ms-input-placeholder { color: #999999; }

.safari .box-field .form-control { line-height: normal; }

.insta-photos { display: flex; }

.insta-photo { width: 16.666%; height: 320px; position: relative; margin-top: 130px; }

.insta-photo img { width: 100%; height: 100%; object-fit: cover; }

.insta-photo__hover { position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: #222222; opacity: 0; transition: all 0.3s linear; }

.insta-photo__hover i { font-size: 70px; }

.insta-photo:hover .insta-photo__hover { opacity: .5; }

.detail-block { padding-top: 218px; background-image: url(../img/detail-main-bg.jpg); background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; text-align: center; z-index: 1; }

.detail-block h1 { margin-bottom: 8px; }

.detail-block__items { display: flex; margin-left: -15px; margin-right: -15px; margin-top: 57px; margin-bottom: -60px; }

.detail-block__item { margin: 0 15px; width: calc(33.3% - 30px); background: #FAF9FF; border: 1px solid #EEEEEE; padding: 26px 56px 26px 42px; display: flex; align-items: center; text-align: left; }

.detail-block__item-icon { width: 80px; height: 67.3px; margin-right: 27px; position: relative; }

.detail-block__item-icon i { position: absolute; font-size: 50px; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.detail-block__item-info { font-size: 16px; line-height: 170%; color: #666666; margin-top: 5px; }

.detail-block__item-info h6 { display: block; margin-bottom: 1px; }

.bread-crumbs { display: flex; justify-content: center; align-items: center; }

.bread-crumbs li { margin: 0 13px; font-size: 16px; line-height: 170%; color: #D05278; position: relative; }

.bread-crumbs li:after { position: absolute; top: 40%; transform: translateY(-50%); right: -15px; content: '-'; color: #666666; }

.bread-crumbs li a { color: #666666; }

.bread-crumbs li a:hover { color: #D05278; }

.bread-crumbs li:last-of-type:after { display: none; }

.promo-video { padding-top: 245px; margin-bottom: 185px; position: relative; }

.promo-video__decor { position: absolute; left: -13px; top: 3.5%; width: 29%; z-index: -1; }

.promo-video__block { position: relative; width: 100%; height: 550px; margin-top: -5px; }

.promo-video__block iframe { z-index: 2; position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

.promo-video__block img { width: 100%; height: 100%; object-fit: cover; }

.promo-video__block .info-blocks__item-img-overlay { background-color: rgba(34, 34, 34, 0.4); }

.promo-video__nums { margin-top: 120px; display: flex; justify-content: space-between; padding: 0 45px 0 22px; }

.promo-video__num { text-align: center; }

.promo-video__num span { position: relative; font-weight: bold; font-size: 54px; line-height: 100%; text-transform: capitalize; color: #D05278; display: block; margin-bottom: 42px; z-index: 1; }

.promo-video__num span:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 110.52px; height: 94.85px; background-image: url(../img/promo-video__num-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; z-index: -1; }

.discount-about { padding: 191px 0; }

.discount-about .discount-info { margin-right: 85px; }

.discount-about .discount-info p { padding-left: 0px; }

.discount-about .discount-info p.discount-info__sm { margin-top: 23px; }

.discount-about .discount-info .btn { margin-left: 0; }

.discount-about .saint-text { font-size: 50px; padding-left: 0px; }

.discount-about h2 { margin-bottom: 22px; }

.testimonials { padding-top: 191px; margin-bottom: -5px; }

.testimonials-slider { padding: 0 195px; max-width: 93%; margin: 0 auto; }

.testimonials-slide { text-align: center; }

.testimonials-slide p { font-size: 20px; line-height: 150%; font-family: 'Tenor Sans'; }

.testimonials-author { margin-top: 52px; }

.testimonials-author img { width: 50px; height: 50px; border-radius: 50%; object-fit: cover; margin: 0 auto; margin-bottom: 11px; }

.testimonials .slick-arrow { top: 29px; }

.contacts-info { padding-top: 240px; padding-bottom: 74px; }

.contacts-info__content { display: flex; justify-content: space-between; align-items: center; }

.contacts-info__text { width: calc(100% - 295px); }

.contacts-info__text h4 { margin-bottom: 30px; }

.contacts-info__text p { letter-spacing: .15px; }

.contacts-info__social { margin-top: -7px; }

.contacts-info__social span { font-size: 16px; line-height: 170%; color: #222222; display: block; margin-bottom: 8px; }

.contacts-info__social ul { display: flex; }

.contacts-info__social ul li { margin-right: 10px; }

.contacts-info__social ul li:last-of-type { margin-right: 0; }

.contacts-info__social ul li a { width: 45px; height: 45px; display: flex; justify-content: center; align-items: center; background: #FAF9FF; border: 1px solid #EEEEEE; }

.contacts-info__social ul li a i:before { color: #222222; transition: all 0.3s linear; }

.contacts-info__social ul li a:hover { background: #222222; border-color: #222222; }

.contacts-info__social ul li a:hover i:before { color: #FAF9FF; }

.main-logos_contacts { padding-bottom: 182px; }

.discount-contacts { padding: 182px 0 176px; }

.discount-contacts .saint-text { font-size: 50px; padding-left: 0px; }

.discount-contacts .main-text { margin-bottom: 22px; }

.discount-contacts .discount-info { max-width: 470px; margin-right: 82px; }

.discount-contacts .discount-info p { padding-left: 0; }

.discount form { margin-top: 44px; }

.discount form .box-field { margin-bottom: 10px; }

.discount form .btn { margin-top: -2px; margin-left: 0px; }

.box-field__textarea textarea.form-control { height: 120px; resize: none; line-height: normal; }

.insta-photos_contacts .insta-photo { margin-top: 0; }

.contacts-map { width: 100%; height: 400px; }

.contacts-map #map { width: 100%; height: 100%; pointer-events: none; position: relative; }

.contacts-map #map:after { position: absolute; width: 100%; height: 100%; left: 0; top: 0; content: ''; mix-blend-mode: multiply; background-color: #FCEDEA; }

@supports (-ms-ime-align: auto) { .contacts-map #map:after { background-color: rgba(252, 237, 234, 0.5); } }

.detail-block_margin { padding-bottom: 117px; }

.all-categories { padding-top: 180px; padding-bottom: 20px; }

.all-categories .top-categories__items { flex-wrap: wrap; }

.all-categories .top-categories__item { margin-bottom: 30px; }

.faq { padding-top: 184px; }

.faq .promo-video__decor { top: 14%; }

.faq-search { margin-bottom: 52px; }

.faq-item { margin-bottom: 41px; transition: all 0.3s linear; }

.faq-item:last-of-type { margin-bottom: 0; }

.faq-item__head { cursor: pointer; transition: all 0.3s linear; position: relative; font-size: 20px; line-height: 150%; color: #222222; font-family: 'Tenor Sans'; padding-bottom: 18px; display: flex; align-items: center; }

.faq-item__head:hover { opacity: .8; }

.faq-item__head-num { font-family: 'Lato'; position: relative; width: 40px; text-align: center; display: inline-block; margin-right: 30px; color: #222222; padding-top: 2px; }

.faq-item__head-num:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 40px; height: 34.33px; background-image: url(../img/main-text-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; }

.faq-item__head-btn { width: 40px; height: 40px; border-radius: 50%; background: #FAF9FF; border: 1px solid #EEEEEE; line-height: 40px; text-align: center; font-size: 14px; font-weight: 700; position: absolute; right: 0; bottom: -20px; z-index: 1; }

.faq-item__head-btn:before, .faq-item__head-btn:after { content: ""; position: absolute; background: #222222; }

.faq-item__head-btn:before { left: 50%; top: 50%; width: 2px; height: 8px; transform: translate(-50%, -50%); }

.faq-item__head-btn:after { top: 50%; left: 50%; height: 2px; width: 8px; transform: translate(-50%, -50%); }

.faq-item__head:after { position: absolute; right: 0; bottom: 0; content: ''; background-color: #EEEEEE; width: 95.5%; height: 1px; }

.faq-item__content { padding-top: 0; margin-top: 45px; padding-bottom: 46px; padding-left: 119px; padding-right: 122px; display: none; position: relative; }

.faq-item__content:after { position: absolute; right: 0; bottom: 0; content: ''; background-color: #EEEEEE; width: 95.5%; height: 1px; }

.faq-item.active .faq-item__head-num { color: #222222; }

.faq-item.active .faq-item__head { color: #D05278; }

.faq-item.active .faq-item__head-btn { background: #FCEDEA; border-color: #FCEDEA; }

.faq-item.active .faq-item__head-btn:before { display: none; }

.faq-more { font-size: 14px; line-height: 170%; text-transform: uppercase; margin-top: 81px; }

.faq-more i { font-size: 8px; margin-left: 10px; transition: all 0.3s linear; }

.faq-more a { display: flex; align-items: center; }

.faq-more a:hover { opacity: .8; }

.faq-more a:hover i { margin-left: 15px; }

.box-field__row-search { width: 100%; display: flex; justify-content: space-between; }

.box-field__row-search .box-field { width: calc(100% - 193px); }

.blog { margin-top: 180px; }

.blog-items { margin-bottom: 3px; }

.blog .promo-video__decor { top: 13%; }

.paging-list { display: flex; justify-content: center; align-items: center; }

.paging-list__link { min-width: 40px; height: 60px; display: flex; justify-content: center; align-items: center; font-size: 16px; line-height: 170%; color: #666666; background: #FAF9FF; border: 1px solid #EEEEEE; }

.paging-list__link i { font-size: 20px; }

.paging-list__link i:before { color: #999999; }

.paging-list__link:hover { background: #222222; border-color: #222222; color: #FFFFFF; }

.paging-list__link:hover i:before { color: #FFFFFF; }

.paging-list__item { margin: 0 2.5px; }

.paging-list__item.active .paging-list__link { background: #222222; border-color: #222222; color: #FFFFFF; }

.paging-list__item.active .paging-list__link i:before { color: #FFFFFF; }

.paging-list__item.paging-next .paging-list__link i { transform: rotate(180deg); }

.post { padding-top: 180px; padding-bottom: 48px; }

.post .promo-video__decor { top: 11.5%; }

.post-top { text-align: center; border-bottom: 1px solid #EEEEEE; }

.post-top p { margin-top: 30px; margin-bottom: 60px; font-size: 20px; line-height: 150%; color: #666666; font-family: 'Tenor Sans'; }

.post-top img { width: 100%; }

.post-top__info { display: flex; padding: 30px 0 21px; padding-right: 20px; }

.post-top__info li { font-size: 14px; line-height: 170%; color: #999999; display: flex; align-items: center; }

.post-top__info li a { color: #222222; }

.post-top__info li a:hover { opacity: .8; }

.post-top__info li i { margin-right: 10px; }

.post-top__info li i:before { display: block; }

.post-top__user { margin-left: 58px; }

.post-top__watch { margin-left: auto; }

.post-top__watch i { font-size: 10px; }

.post-top__comment { margin-left: 30px; }

.post-content { padding: 60px 98px 0; font-size: 16px; line-height: 170%; color: #666666; }

.post-content h6 { border-top: 1px solid #EEEEEE; margin-top: 52px; padding-top: 60px; margin-bottom: 6px; }

.post-content blockquote { margin-top: 53px; }

.post-list { display: flex; flex-wrap: wrap; border-top: 1px solid #EEEEEE; justify-content: space-between; padding-top: 11px; }

.post-list li { width: 47%; margin-top: 50px; align-self: flex-start; }

.post-list li span { display: block; margin-bottom: 6px; font-size: 20px; line-height: 120%; font-family: 'Tenor Sans'; color: #222222; text-transform: capitalize; }

.post-list li p { font-size: 16px; line-height: 170%; color: #666666; }

.post-list li:last-of-type { margin-top: 25px; }

.post .discount { width: 100vw; margin-left: 50%; transform: translateX(-50%); margin-bottom: 100px; margin-top: 95px; padding-top: 177px; padding-bottom: 165px; }

.post .discount h2 { margin-top: 10px; }

.post .discount .discount-info { max-width: 535px; margin-right: 15px; }

.post-bottom { margin-top: 30px; padding-left: 98px; padding-right: 98px; }

.post-bottom__info { display: flex; justify-content: space-between; align-items: center; }

.post-bottom__tags { display: flex; align-items: center; margin-top: -8px; }

.post-bottom__tags span { color: #222222; font-size: 16px; margin-right: 20px; }

.post-bottom__tags ul { display: flex; }

.post-bottom__tags li { margin-right: 8px; }

.post-bottom__tags li a { border: 1px solid #EEEEEE; padding: 10.5px 20px; color: #666666; display: block; }

.post-bottom__tags li a:hover { opacity: .8; }

.post-bottom .contacts-info__social { display: flex; align-items: center; margin-top: 0; }

.post-bottom .contacts-info__social span { margin-right: 19px; margin-bottom: 0; }

.post-bottom__nav { margin-top: 30px; padding-top: 30px; border-top: 1px solid #EEEEEE; display: flex; justify-content: space-between; }

.post-bottom__nav a { display: flex; align-items: center; font-size: 14px; line-height: 170%; text-transform: uppercase; color: #999999; }

.post-bottom__nav a i { background: #FAF9FF; border: 1px solid #EEEEEE; padding: 17px 12px; font-size: 22px; margin-right: 32px; transition: all 0.3s linear; }

.post-bottom__nav a:hover { color: #000000; }

.post-bottom__nav a:hover i { background: #222222; border-color: #222222; }

.post-bottom__nav a:hover i:before { color: #fff; }

.post-bottom__nav a:last-of-type i { transform: rotate(180deg); margin-right: 0; margin-left: 32px; }

.post-comments { margin-top: 61px; padding-left: 98px; padding-right: 98px; }

.post-comments h3 { margin-bottom: 30px; }

.post-comment { margin-bottom: 19px; border: 1px solid #EEEEEE; padding: 40px 38px 34px; }

.post-comment__head { display: flex; align-items: center; justify-content: space-between; }

.post-comment__author { display: flex; align-items: center; }

.post-comment__author img { width: 40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 30px; }

.post-comment__name { font-size: 16px; color: #222222; margin-right: 40px; position: relative; }

.post-comment__name:after { position: absolute; right: -21px; top: 50%; transform: translateY(-50%); content: ''; width: 1px; height: 10px; background-color: #eee; }

.post-comment__date { color: #999999; font-size: 14px; }

.post-comment__reply { display: flex; align-items: center; }

.post-comment__reply i { margin-right: 10px; font-size: 10px; }

.post-comment__reply:hover { opacity: .8; }

.post-comment__content { margin-top: 19px; color: #666666; font-size: 16px; line-height: 170%; }

.post-comment__answer { background: #FAF9FF; margin-left: 30px; }

.post-comment__form { background: #FCEDEA; padding: 60px; margin-top: 79px; overflow: hidden; position: relative; }

.post-comment__form .subscribe-form__img { position: absolute; right: 85px; bottom: -33px; padding: 0; margin: 0; width: 208px; }

.post-comment__form .subscribe-form__img:after { background-image: url(../img/comment-form-decor.png); left: 50%; top: 0%; width: 380px; }

.post-comment__form form { z-index: 10; position: relative; }

.post-comment__form h3 { margin-bottom: 2px; }

.post-comment__form p { margin-bottom: 52px; }

.post-comment__form .box-field__row { margin-left: -5.5px; margin-right: -5.5px; display: flex; }

.post-comment__form .box-field__row .box-field { margin-left: 5.5px; margin-right: 5.5px; width: calc(33.3% - 11px); }

.post-comment__form .box-field { margin-bottom: 10px; }

.post-comment__form .box-field__textarea textarea.form-control { height: 100px; }

blockquote, .blockquote { font-size: 20px; line-height: 150%; font-family: 'Tenor Sans'; color: #222222; padding: 45px 72px; background: #FAF9FF; position: relative; margin-bottom: 70px; }

blockquote:after, .blockquote:after { content: ''; position: absolute; right: 0; bottom: 0; width: 270px; height: 228px; background-image: url(../img/blockquote-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; }

.blockquote-author { display: block; color: #D05278; text-transform: capitalize; font-size: 24px; line-height: 100%; font-family: 'Tenor Sans'; margin-top: 23px; }

.discount ul { margin-top: 22px; }

.discount li { font-size: 16px; line-height: 170%; color: #666666; margin-bottom: 0px; }

.discount li span { color: #222222; }

.shop { padding-top: 180px; position: relative; }

.shop .promo-video__decor { top: 2.5%; }

.shop-decor { position: absolute; right: 0; top: 57%; width: 32.5%; z-index: -1; }

.shop-content { display: flex; justify-content: space-between; }

.shop-aside { width: 232px; }

.shop-aside .box-field__search { position: relative; margin-bottom: 35px; }

.shop-aside .box-field__search i { position: absolute; right: 21px; top: 50%; transform: translateY(-50%); font-size: 18px; }

.shop-aside .box-field__search .form-control { padding-right: 50px; }

.shop-aside__item { margin-bottom: 55px; }

.shop-aside__item-title { display: block; font-size: 24px; line-height: 100%; text-transform: capitalize; padding-bottom: 10px; color: #222222; font-family: 'Tenor Sans'; border-bottom: 2px solid #222222; margin-bottom: 15px; }

.shop-aside__item ul li a { font-size: 16px; line-height: 170%; color: #666666; display: block; padding: 13px 0; border-bottom: 1px solid #EEEEEE; }

.shop-aside__item ul li a span { margin-left: 8px; color: #999999; font-size: 12px; }

.shop-aside__item ul li a:hover { color: #D05278; border-color: #D05278; }

.shop-aside__item ul li a:hover span { color: #D05278; }

.shop-aside__item .range-slider { margin-bottom: 123px; }

.shop-aside__item-product { display: flex; margin-bottom: 30px; }

.shop-aside__item-product:first-of-type { margin-top: 30px; }

.shop-aside__item-product:last-of-type { margin-bottom: 0; }

.shop-aside__item-product-img { width: 60px; height: 70px; }

.shop-aside__item-product-img img { width: 100%; height: 100%; }

.shop-aside__item-product-info { margin-left: 15px; }

.shop-aside__item-product-title { display: block; font-size: 16px; line-height: 170%; color: #222222; margin-bottom: -4px; transition: all 0.3s linear; }

.shop-aside__item-product-price { color: #999999; font-size: 12px; line-height: 170%; margin-bottom: 5px; display: block; }

.shop-aside__item-product:hover .shop-aside__item-product-title { color: #D05278; }

.shop-main { width: calc(100% - 294px); }

.shop-main__filter { display: flex; justify-content: space-between; align-items: center; margin-bottom: 32px; }

.shop-main__items { display: flex; flex-wrap: wrap; margin-left: -15px; margin-right: -15px; margin-bottom: 15px; }

.shop-main__items .products-item { width: calc(33.3% - 30px); margin-left: 15px; margin-right: 15px; margin-bottom: 56px; }

.shop-main__items .products-item__img { height: 370px; }

.shop-main__items .products-item__name { font-size: 20px; line-height: 120%; margin-bottom: 8px; }

.shop-main__items .products-item__cost { font-size: 18px; line-height: 100%; font-weight: 400; }

.shop-main__items .products-item__cost span { font-size: 16px; line-height: 170%; }

.shop-main__items .products-item__info { margin-top: 30px; }

.shop-main__items .products-item__sale, .shop-main__items .products-item__new { font-size: 12px; padding: 10px 26px; }

.shop-main__checkboxes { display: flex; }

.box-field__search input[type="search"]::-webkit-search-decoration, .box-field__search input[type="search"]::-webkit-search-cancel-button, .box-field__search input[type="search"]::-webkit-search-results-button, .box-field__search input[type="search"]::-webkit-search-results-decoration { -webkit-appearance: none; }

.irs { height: auto; font-family: 'Lato', sans-serif; margin-top: -15px; }

.irs .irs-line { background-color: #EEEEEE; }

.irs .irs-bar { height: 3px; background-color: #D05278; }

.irs .irs-handle { background-color: #D05278; box-shadow: none; border-color: #D05278; width: 15px; height: 15px; top: 30px; cursor: pointer; }

.irs .irs-handle:hover, .irs .irs-handle.state_hover { background-color: #D05278; }

.irs .irs-from, .irs .irs-to, .irs .irs-single { font-size: 14px; color: #666666; padding: 0; background-color: transparent; top: auto; bottom: -63px; }

.irs .irs-from:before, .irs .irs-to:before, .irs .irs-single:before { display: none; }

.star-rating { display: flex; }

.star-rating li { width: 13px; }

.star-rating li i { color: #CFC819; font-size: 13px; }

.checkbox-box { position: relative; cursor: pointer; font-size: 14px; color: #666666; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; background: #FAF9FF; border: 1px solid #EEEEEE; padding: 11.5px 16px; display: flex; align-items: center; margin-right: 19px; }

.checkbox-box input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }

.checkmark { width: 15px; height: 15px; border: 1px solid #EEEEEE; background-color: #fff; margin-right: 10px; }

.checkbox-box:hover input ~ .checkmark { background-color: #fff; transition: all 0.3s linear; }

.checkbox-box input:checked ~ .checkmark { background-color: #D05278; }

.checkmark:after { content: ""; position: absolute; display: none; }

.checkbox-box input:checked ~ .checkmark:after { display: block; }

.jq-selectbox__select { box-shadow: none; font-family: 'Lato', sans-serif; border: 1px solid #EEEEEE !important; border-radius: 0; background: #FAF9FF; font-size: 16px; line-height: 170%; padding: 6px 30px; color: #666666; width: 270px; }

.jq-selectbox__select:hover { border: 1px solid #EEEEEE; background: #FAF9FF; }

.jq-selectbox__select-text { width: 100%; padding-right: 25px; }

.jq-selectbox.focused .jq-selectbox__select { border: 1px solid #EEEEEE; background: #FAF9FF; }

.jq-selectbox__trigger { top: 4px; right: 18px; }

.jq-selectbox__trigger-arrow { border-top: 6px solid #666666; border-right: 5px solid transparent; border-left: 5px solid transparent; }

.jq-selectbox__dropdown { box-shadow: none; border-radius: 0; border: 1px solid #EEEEEE; margin-top: -1px; }

.jq-selectbox__dropdown li { padding: 7px 30px; font-size: 16px; line-height: 170%; font-family: 'Lato', sans-serif; transition: all 0.3s linear; color: #666666; }

.jq-selectbox__dropdown li:hover, .jq-selectbox__dropdown li.sel { background-color: #222222; color: #fff; }

.jq-selectbox__dropdown li.sel { background-color: #fff; color: #D05278; }

.product { padding-top: 180px; }

.product .promo-video__decor { top: 11.7%; width: 30%; }

.product-content { display: flex; justify-content: space-between; }

.product-slider { width: 570px; }

.product-slider__main { width: 100%; margin-bottom: 10px; }

.product-slider__main img { width: 100%; height: 570px; object-fit: cover; }

.product-slider__nav { margin-left: -5px; margin-right: -5px; }

.product-slider__nav-item { height: 135px; margin-left: 5px; margin-right: 5px; cursor: pointer; transition: all 0.3s linear; position: relative; border: 2px solid transparent; }

.product-slider__nav-item:after { position: absolute; width: 100%; height: 100%; top: 0; left: 0; content: ''; background-color: rgba(34, 34, 34, 0.15); opacity: 0; transition: all 0.3s linear; }

.product-slider__nav-item img { width: 100%; height: 100%; object-fit: cover; }

.product-slider__nav-item:hover:after { opacity: 1; }

.product-slider__nav-item.slick-current { border: 2px solid #D05278; }

.product-slider__nav-item.slick-current:hover:after { opacity: 0; }

.product-stock { display: block; margin-top: 15px; font-size: 12px; line-height: 170%; text-transform: uppercase; color: #12A33B; }

.product-num { font-size: 12px; line-height: 170%; color: #666666; display: block; }

.product-price { margin-top: 30px; color: #222222; font-size: 32px; line-height: 130%; text-transform: capitalize; display: block; margin-bottom: 31px; }

.product-price span { margin-right: 20px; font-size: 20px; line-height: 100%; text-decoration-line: line-through; color: #999999; }

.product-options { margin-top: 46px; padding-top: 45px; border-top: 1px solid #eee; display: flex; justify-content: space-between; }

.product-info { width: calc(100% - 630px); }

.product-info .contacts-info__social { margin-top: 39px; display: flex; align-items: center; }

.product-info .contacts-info__social span { display: inline-block; margin-bottom: 0; margin-right: 30px; }

.product-info__color span { display: block; color: #222222; margin-bottom: 30px; }

.product-info__color ul { display: flex; }

.product-info__color li { width: 25px; height: 25px; margin-right: 5px; border: 2px solid transparent; transition: all 0.3s linear; cursor: pointer; }

.product-info__color li:hover, .product-info__color li.active { border: 2px solid #D05278; }

.product-info__quantity-title { display: block; color: #222222; margin-bottom: 15px; }

.product-buttons { margin-top: 60px; display: flex; }

.product-buttons .btn { margin-right: 11px; padding: 0 49px; }

.product-buttons .btn i { margin-right: 10px; }

.product-detail { margin-top: 60px; padding-bottom: 60px; border-bottom: 1px solid #eee; }

.product-detail__form { margin-top: 0; width: 410px; padding: 68px 60px 65px; align-self: flex-start; }

.product-detail__form h4 { margin-bottom: 11px; margin-left: 3px; }

.product-detail__form p { margin-bottom: 38px; margin-left: 3px; }

.product-detail__form .rating { margin-bottom: 21px; }

.product-detail__form .box-field__textarea textarea.form-control { height: 148px; }

.product-detail__form .subscribe-form__img { position: absolute; right: 14px; bottom: -10px; padding: 0; margin: 0; width: 160px; }

.product-detail__form .subscribe-form__img:after { top: 30%; width: 225px; }

.product-detail__items { width: calc(100% - 470px); }

.product-detail__items .blog-item__link { margin-top: 62px; }

.product-reviews { display: flex; justify-content: space-between; }

.product-viewed { margin-bottom: 50px; }

.nav-tab-list { display: flex; margin-bottom: 30px; }

.nav-tab-list li { margin-right: 15px; }

.nav-tab-list li a { display: block; background: #FAF9FF; border: 1px solid #EEEEEE; padding: 6px 29px; font-size: 16px; line-height: 170%; color: #666666; }

.nav-tab-list li.active a { background: #D05278; border-color: #D05278; color: #FFFFFF; }

.review-item { margin-bottom: 19px; border: 1px solid #EEEEEE; padding: 40px 38px 34px; padding-bottom: 39px; }

.review-item__head { display: flex; align-items: center; }

.review-item__author { display: flex; align-items: center; }

.review-item__author img { width: 40px; height: 40px; border-radius: 50%; object-fit: cover; margin-right: 30px; }

.review-item__name { font-size: 16px; color: #222222; margin-right: 40px; position: relative; }

.review-item__name:after { position: absolute; right: -21px; top: 50%; transform: translateY(-50%); content: ''; width: 1px; height: 10px; background-color: #eee; }

.review-item__date { color: #999999; font-size: 14px; position: relative; }

.review-item__date:after { position: absolute; right: -29px; top: 50%; transform: translateY(-50%); content: ''; width: 1px; height: 10px; background-color: #eee; }

.review-item__rating { margin-left: 49px; }

.review-item__content { margin-top: 19px; color: #666666; font-size: 16px; line-height: 170%; }

.counter-box { display: flex; align-items: center; }

.counter-link { background: #FAF9FF; border: 1px solid #EEEEEE; display: flex; align-items: center; justify-content: center; transition: all 0.3s linear; width: 40px; height: 40px; cursor: pointer; }

.counter-link i { font-size: 14px; }

.counter-link i:before { color: #999999; }

.counter-link:hover { background: #222222; border: 1px solid #222222; }

.counter-link:hover i:before { color: #fff; }

.counter-link.counter-link__next { transform: rotate(180deg); }

.counter-input { width: 60px; height: 40px; background-color: transparent; border: none; outline: none; box-shadow: none; color: #222222; text-align: center; }

.rating { display: flex; align-items: center; }

.rating i { font-size: 18px; margin-right: -1px; display: block; position: relative; color: #fff; cursor: pointer; transition: all 0.3s linear; font-family: 'icomoon'; }

.rating i.active { color: #CFC819; }

.rating .count-star { display: none; }

.cart { padding-top: 185px; }

.cart .promo-video__decor { top: 17%; }

.cart-table { width: 100%; }

.cart-table__row { display: flex; justify-content: space-between; align-items: center; padding: 29px 0; border: 1px solid #EEEEEE; margin-bottom: 20px; }

.cart-table__row-head { font-size: 20px; line-height: 120%; font-family: 'Tenor Sans'; color: #222222; text-transform: capitalize; border: none; border-bottom: 2px solid #222222; padding: 0; padding-bottom: 9px; margin-bottom: 30px; }

.cart-table__col { width: 12%; }

.cart-table__col:first-of-type { width: 50%; padding-left: 30px; display: flex; }

.cart-table__col:nth-of-type(3) { width: 23%; padding-left: 34px; }

.cart-table__img { width: 110px; height: 110px; margin-right: 28px; display: inline-block; vertical-align: top; }

.cart-table__img img { width: 100%; height: 100%; object-fit: cover; }

.cart-table__img:hover { opacity: .8; }

.cart-table__info { display: inline-block; vertical-align: top; margin-top: 10px; }

.cart-table__info .title5:hover { color: #D05278; }

.cart-table__info-stock { display: block; font-size: 12px; line-height: 170%; text-transform: uppercase; color: #12A33B; margin-top: 10px; }

.cart-table__info-num { display: block; font-size: 12px; line-height: 170%; color: #666666; }

.cart-table__price { color: #666666; font-size: 18px; line-height: 100%; }

.cart-table__price span { display: block; margin-bottom: 10px; color: #999999; font-size: 14px; line-height: 100%; text-decoration-line: line-through; text-transform: capitalize; }

.cart-table__total { font-weight: 600; font-size: 20px; line-height: 150%; color: #222222; }

.cart-bottom { margin-top: 60px; display: flex; margin-bottom: 49px; justify-content: space-between; }

.cart-bottom__promo { width: calc(100% - 600px); }

.cart-bottom__promo-form { margin-bottom: 46px; }

.cart-bottom__promo-form .box-field__row { justify-content: space-between; }

.cart-bottom__promo-form .box-field__row .box-field { width: calc(100% - 196px); }

.cart-bottom__promo-form .btn { padding: 0 49px; }

.cart-bottom__promo p { max-width: 480px; margin-top: 11px; }

.cart-bottom__promo .contacts-info__social { margin-top: 32px; }

.cart-bottom__total { width: 540px; background: #FAF9FF; border: 1px solid #EEEEEE; padding: 60px; }

.cart-bottom__total .btn { width: 100%; text-align: center; }

.cart-bottom__total-goods { display: flex; justify-content: space-between; padding-bottom: 17px; border-bottom: 1px solid #EEEEEE; margin-bottom: 24px; font-size: 16px; line-height: 170%; color: #222222; align-items: flex-end; }

.cart-bottom__total-goods span { font-size: 18px; line-height: 100%; color: #666666; }

.cart-bottom__total-promo { display: flex; justify-content: space-between; align-items: flex-end; }

.cart-bottom__total-promo span { font-size: 18px; line-height: 100%; text-transform: capitalize; color: #666666; }

.cart-bottom__total-num { display: flex; justify-content: space-between; margin-bottom: 60px; font-size: 24px; line-height: 100%; text-transform: capitalize; align-items: flex-end; padding-top: 29px; border-top: 2px solid #222222; margin-top: 23px; }

.cart-bottom__total-num span { font-size: 24px; line-height: 100%; color: #222222; text-transform: capitalize; }

.cart-bottom__total-delivery { display: flex; justify-content: space-between; align-items: flex-end; margin-top: 24px; padding-top: 20px; border-top: 1px solid #EEEEEE; font-size: 16px; line-height: 170%; color: #222222; }

.cart-bottom__total-delivery-date { margin-right: auto; margin-left: 1px; color: #999999; font-size: 16px; }

.cart-bottom__total-delivery span { font-size: 18px; }

.cart-bottom__total-delivery span.cart-bottom__total-delivery-date { font-size: 16px; }

.wishlist { padding-top: 185px; }

.wishlist .promo-video__decor { left: -18px; top: 13%; width: 25%; }

.wishlist-stock { display: block; font-size: 12px; line-height: 170%; text-transform: uppercase; color: #12A33B; }

.wishlist-available { display: block; font-size: 12px; line-height: 170%; text-decoration-line: line-through; text-transform: uppercase; color: #999999; }

.wishlist .cart-table__info-num { margin-top: 11px; }

.wishlist-buttons { margin-top: 59px; margin-left: -3px; }

.wishlist-buttons .btn { margin-right: 7px; }

.wishlist .cart-table__col { width: 15%; }

.wishlist .cart-table__col:nth-of-type(3) { width: 17%; padding-left: 0px; }

.wishlist .cart-table__col:first-of-type { width: 50%; }

.detail-block__item-icon i:before { color: #222222; }

.detail-block__item-inactive .detail-block__item-info { color: #999999; }

.detail-block__item-inactive .detail-block__item-info h6 { color: #999999; }

.detail-block__item-inactive .detail-block__item-icon i:before { color: #999999; }

.checkout { padding-top: 240px; padding-bottom: 50px; }

.checkout .promo-video__decor { top: 18%; }

.checkout-content { display: flex; justify-content: space-between; }

.checkout-form { width: calc(100% - 600px); }

.checkout-form__item { margin-bottom: 21px; }

.checkout-form__item h4 { margin-bottom: 15px; }

.checkout-form__item .box-field { margin-bottom: 10px; }

.checkout-form__item .box-field:last-of-type { margin-bottom: 0; }

.checkout-form__item .box-field__row { display: flex; justify-content: space-between; }

.checkout-form__item .box-field__row .box-field { width: calc(50% - 5px); }

.checkout-form__item .jq-selectbox__select { height: 60px; background: #FFFFFF; border: 1px solid #FCEDEA; color: #999999; font-size: 14px; margin-bottom: 10px; width: 100%; padding: 17px 30px; }

.checkout-form__item .jq-selectbox.focused .jq-selectbox__select { background: #FFFFFF; border: 1px solid #FCEDEA; }

.checkout-form__item .jq-selectbox__trigger { top: 13px; right: 9px; }

.checkout-form__item .jq-selectbox__dropdown { top: calc(100% - 10px) !important; bottom: auto !important; }

.checkout-form__item .jq-selectbox__dropdown li { font-size: 14px; }

.checkout-form__item textarea { height: 120px; }

.checkout-form__item .checkbox-box { margin-top: 34px; }

.checkout-buttons { display: flex; margin-top: 65px; }

.checkout-buttons .btn { margin-right: 10px; padding: 0 49px; }

.checkout-buttons .btn i { font-size: 11px; font-weight: 700; transition: all 0.3s linear; margin-right: 10px; }

.checkout-buttons .btn-next { padding: 0 49px; }

.checkout-buttons .btn-next i { margin-right: 0; }

.checkout-buttons .btn-next i:before { color: #fff; }

.checkout-buttons .btn-grey i { margin-top: 2px; }

.checkout-buttons .btn-grey i:before { color: #222222; }

.checkout-buttons .btn-grey:hover i:before { color: #fff; }

.checkout-info { width: 540px; padding-top: 55px; }

.checkout-info .cart-bottom__total-num { margin-bottom: -2px; margin-top: 18px; }

.checkout-order { margin-bottom: 30px; }

.checkout-order h5 { padding-bottom: 11px; border-bottom: 2px solid #222222; margin-bottom: 30px; }

.checkout-order__item { border: 1px solid #EEEEEE; padding: 20px 20px; display: flex; margin-bottom: 10px; padding-bottom: 13px; }

.checkout-order__item:last-of-type { margin-bottom: 0; }

.checkout-order__item-img { width: 70px; height: 70px; margin-right: 19px; }

.checkout-order__item-img:hover { opacity: .8; }

.checkout-order__item-img img { width: 100%; height: 100%; object-fit: cover; }

.checkout-order__item-info h6, .checkout-order__item-info .title6 { margin-bottom: 10px; display: block; }

.checkout-order__item-info h6 span, .checkout-order__item-info .title6 span { font-size: 16px; line-height: 100%; margin-left: 3px; display: inline-block; color: #999999; text-transform: initial; font-family: 'Lato', sans-serif; }

.checkout-order__item-info h6:hover, .checkout-order__item-info .title6:hover { color: #D05278; }

.checkout-order__item-price { display: block; color: #222222; font-size: 14px; line-height: 100%; margin-bottom: 10px; }

.checkout-order__item-num { display: block; color: #666666; font-size: 10px; line-height: 170%; }

.checkbox-box__sm { padding: 0; border: none; background: none; font-size: 16px; color: #666666; }

.checkbox-box__sm .checkmark { margin-right: 20px; }

.btn-next i { margin-left: 10px; margin-right: 0px; transform: rotate(180deg); }

.checkout-step2 { padding-bottom: 10px; }

.checkout-step2 .promo-video__decor { top: 18.5%; }

.checkout-payment h4 { margin-bottom: 15px; }

.checkout-payment__item { margin-bottom: 30px; border: 1px solid #EEEEEE; padding: 42px 39px; padding-bottom: 36px; }

.checkout-payment__item-content { display: none; padding-top: 40px; border-top: 1px solid #EEEEEE; margin-top: 16px; }

.checkout-payment__item-content .box-field span { display: block; margin-bottom: 10px; font-size: 14px; line-height: 170%; color: #666666; }

.checkout-payment__item-content .box-field__row { margin-top: 30px; align-items: flex-end; margin-left: -15.5px; margin-right: -15.5px; }

.checkout-payment__item-content .box-field__row .box-field { margin-left: 15.5px; margin-right: 15.5px; width: calc(33.3% - 31px); }

.checkout-payment__item.active { background: #FAF9FF; padding-bottom: 42px; }

.checkout-payment__item.active .checkout-payment__item-content { display: block; }

.checkout-payment .checkout-buttons { margin-top: 58px; }

.radio-box { position: relative; padding-left: 34px; cursor: pointer; color: #222222; font-family: 'Tenor Sans'; font-size: 20px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: flex; align-items: center; text-transform: capitalize; }

.radio-box__info { margin-left: 8px; position: relative; }

.radio-box__info i { font-size: 16px; }

.radio-box__info-content { position: absolute; opacity: 0; visibility: hidden; transition: all 0.3s linear; padding: 27px 29px; padding-left: 30px; background: #FCEDEA; font-size: 12px; line-height: 145%; text-transform: initial; font-family: 'Lato', sans-serif; color: #999999; width: 250px; left: 43px; bottom: 15px; }

.radio-box__info-content:after { position: absolute; left: -8px; bottom: 0; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid #FCEDEA; transform: rotate(180deg); content: ''; }

.radio-box__info:hover .radio-box__info-content { opacity: 1; visibility: visible; z-index: 11; }

.radio-box input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }

.radio-box .checkmark { position: absolute; top: -1px; left: -1px; width: 20px; height: 20px; background-color: #fff; border-radius: 50%; border: 2px solid #999999; }

.radio-box .checkmark:after { content: ""; position: absolute; display: none; }

.radio-box input:checked ~ .checkmark:after { display: block; }

.radio-box .checkmark:after { top: 50%; left: 50%; transform: translate(-50%, -50%); width: 10px; height: 10px; border-radius: 50%; background: #D05278; }

.checkout-purchase h4 { margin-bottom: 15px; }

.checkout-purchase__list { margin-top: 40px; }

.checkout-purchase__list li { display: flex; font-size: 16px; line-height: 170%; color: #999999; padding: 5.3px 29px; border: 1px solid #FAF9FF; background: #fff; }

.checkout-purchase__list li:nth-of-type(odd) { background: #FAF9FF; }

.checkout-purchase__list li span { color: #222222; width: 290px; }

.checkout-purchase__link { margin-top: 60px; font-size: 14px; line-height: 170%; text-transform: uppercase; color: #D05278; display: block; }

.checkout-purchase__link:hover { opacity: .8; }

.checkout-step3 .promo-video__decor { top: 17.3%; }

.checkout-step3 .cart-bottom__total .btn { margin-top: 62px; }

.checkout-step3 .checkout-info { padding-top: 97px; }

.login { padding-top: 180px; }

.login .promo-video__decor { top: 17.5%; }

.login-form { margin: auto; max-width: 570px; padding: 60px; background-position: center; background-repeat: no-repeat; background-size: cover; border: 1px solid #EEEEEE; }

.login-form h3 { text-align: center; margin-bottom: 28px; }

.login-form__social { display: flex; justify-content: center; margin-bottom: 29px; }

.login-form__social li { margin: 0 5px; }

.login-form__social li a { display: block; width: 45px; height: 45px; }

.login-form__social li a:hover { opacity: .7; }

.login-form__social li a i { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; font-size: 15px; }

.login-form__social li a i:before { color: #fff; }

.login-form__social li a i.icon-facebook { background: #3B5999; }

.login-form__social li a i.icon-twitter { background: #55ACEE; }

.login-form__social li a i.icon-insta { background: #E4405F; }

.login-form__social li a i.icon-google { background: #DD4B39; }

.login-form .box-field { margin-bottom: 10px; }

.login-form .checkbox-box { margin-top: 35px; margin-bottom: 35px; }

.login-form .btn { width: 100%; text-align: center; }

.login-form__bottom { display: flex; justify-content: space-between; margin-top: 20px; font-size: 16px; line-height: 170%; }

.login-form__bottom span { color: #666666; }

.login-form__bottom a { color: #D05278; }

.login-form__bottom a:hover { opacity: .8; }

.registration .promo-video__decor { top: 16.7%; }

.registration .login-form { max-width: 770px; }

.registration .box-field__row { display: flex; margin-left: -5px; margin-right: -5px; flex-wrap: wrap; }

.registration .box-field__row span { margin-top: 20px; display: block; width: 100%; margin-left: 5px; font-size: 20px; line-height: 120%; font-family: 'Tenor Sans'; text-transform: capitalize; margin-bottom: 11px; }

.registration .box-field__row .box-field { margin: 0 5px; margin-bottom: 10px; width: calc(50% - 10px); }

.registration .login-form .checkbox-box { margin-top: 25px; margin-bottom: 35px; }

.error-page { padding-top: 148px; }

.error-page__content { display: flex; justify-content: space-between; }

.error-page__info { max-width: 606px; width: 100%; margin-right: 84px; margin-top: 33px; }

.error-page__title { font-size: 40px; line-height: 100%; font-family: 'Tenor Sans'; color: #2F3237; margin-bottom: 20px; text-transform: capitalize; }

.error-page__title span { font-size: 150px; line-height: 100%; margin-right: 23px; }

.error-page__subtitle { color: #666666; font-size: 16px; line-height: 170%; margin-bottom: 24px; }

.error-page__subtitle a:hover { opacity: .8; }

.error-page .box-field__row-search .btn { padding: 0 22px; width: 60px; }

.error-page .box-field__row-search .btn i { margin-right: 0; }

.error-page .box-field__row-search .box-field { width: calc(100% - 60px); }

.error-descr { margin-top: 8px; display: block; font-size: 16px; line-height: 170%; color: #666666; }

input[type=search]::-ms-clear { display: none; width: 0; height: 0; }

input[type=search]::-ms-reveal { display: none; width: 0; height: 0; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration { display: none; }

.profile { padding-top: 180px; padding-bottom: 50px; position: relative; }

.profile-content { display: flex; justify-content: space-between; }

.profile-aside { width: 360px; }

.profile-aside__subscribe { background: #FCEDEA; width: 100%; padding: 40px 40px 48px; position: relative; z-index: 1; }

.profile-aside__subscribe h3 { margin-bottom: 30px; text-align: center; }

.profile-aside__subscribe .box-field { margin-bottom: 10px; }

.profile-aside__subscribe .btn { width: 100%; }

.profile-aside__subscribe img { position: absolute; right: 0; bottom: 0; z-index: -1; width: 77%; }

.profile-aside__viewed { margin-top: 55px; }

.profile-aside__viewed h5 { padding-bottom: 10px; border-bottom: 2px solid #222222; }

.profile-aside__viewed-item { margin-top: 28px; display: flex; align-items: center; }

.profile-aside__viewed-item-img { width: 60px; height: 72px; margin-right: 20px; }

.profile-aside__viewed-item-img img { width: 100%; height: 100%; object-fit: cover; }

.profile-aside__viewed-item-title { font-size: 20px; line-height: 150%; display: block; font-family: 'Tenor Sans'; margin-bottom: 5px; color: #222222; }

.profile-aside__viewed-item-title:hover { color: #D05278; }

.profile-aside__viewed-item-price { font-size: 18px; line-height: 100%; color: #999999; }

.profile-aside__discount { margin-top: 49px; padding: 62px 40px 40px; background-position: center; background-size: cover; background-repeat: no-repeat; }

.profile-aside__discount-title { font-size: 40px; line-height: 110%; color: #222222; text-align: right; font-family: 'Tenor Sans'; margin-bottom: 38px; }

.profile-aside__discount-title span { font-size: 60px; color: #D05278; position: relative; display: inline-block; margin-top: 16px; }

.profile-aside__discount-title span:before { content: ''; position: absolute; left: 65%; top: 50%; transform: translate(-50%, -50%); width: 87px; height: 75px; background-image: url(../img/main-text-decor.png); background-size: contain; background-repeat: no-repeat; background-position: center; transition: all 0.3s linear; }

.profile-aside__discount .btn { width: 100%; text-align: center; }

.profile-main { width: calc(100% - 420px); }

.profile-orders__row { display: flex; justify-content: space-between; align-items: center; }

.profile-orders__row-head { font-family: 'Tenor Sans'; font-size: 20px; line-height: 120%; text-transform: capitalize; color: #222222; border-bottom: 2px solid #222222; padding-bottom: 10px; margin-bottom: 30px; }

.profile-orders__row-head .profile-orders__col:nth-of-type(2) { padding-left: 23px; }

.profile-orders__row-head .profile-orders__col:nth-of-type(3) { padding-left: 12px; }

.profile-orders__row-head .profile-orders__col:nth-of-type(4) { padding-left: 35px; }

.profile-orders__col { padding-left: 30px; }

.profile-orders__col:nth-of-type(1) { width: 111px; }

.profile-orders__col:nth-of-type(2) { width: 294px; }

.profile-orders__col:nth-of-type(3) { text-align: center; padding: 0; width: 120px; }

.profile-orders__col:nth-of-type(4) { width: 195px; }

.profile-orders__item { border: 1px solid #EEEEEE; margin-bottom: 10px; }

.profile-orders__item:last-of-type { margin-bottom: 0; }

.profile-orders__item .profile-orders__row { padding: 17px 0; padding-right: 0; transition: all 0.3s linear; background-color: #fff; }

.profile-orders__item-date { color: #999999; font-size: 14px; line-height: 170%; }

.profile-orders__item-addr { color: #666666; font-size: 14px; line-height: 170%; }

.profile-orders__item-price { font-size: 16px; line-height: 170%; color: #666666; }

.profile-orders__item .profile-orders__col { padding: 0 30px; position: relative; }

.profile-orders__item .profile-orders__col:after { position: absolute; right: 0; top: 50%; transform: translateY(-50%); content: ''; height: 35px; width: 1px; background-color: #EEEEEE; }

.profile-orders__item .profile-orders__col:last-of-type:after { display: none; }

.profile-orders__item .profile-orders__col:nth-of-type(3) { padding: 0; }

.profile-orders__item .profile-orders__col-btn { width: 40px; height: 40px; border-radius: 50%; background: #FAF9FF; border: 1px solid #EEEEEE; line-height: 40px; text-align: center; font-size: 14px; font-weight: 700; position: absolute; right: 25px; top: 50%; transform: translateY(-50%); z-index: 1; cursor: pointer; transition: all 0.3s linear; }

.profile-orders__item .profile-orders__col-btn:hover { opacity: .8; }

.profile-orders__item .profile-orders__col-btn:before, .profile-orders__item .profile-orders__col-btn:after { content: ""; position: absolute; background: #222222; }

.profile-orders__item .profile-orders__col-btn:before { left: 50%; top: 50%; width: 2px; height: 8px; transform: translate(-50%, -50%); }

.profile-orders__item .profile-orders__col-btn:after { top: 50%; left: 50%; height: 2px; width: 8px; transform: translate(-50%, -50%); }

.profile-orders__item .profile-orders__col-onway { font-size: 12px; line-height: 170%; text-transform: uppercase; color: #D05278; }

.profile-orders__item .profile-orders__col-delivered { font-size: 12px; line-height: 170%; text-transform: uppercase; color: #12A33B; }

.profile-orders__item.active .profile-orders__content { display: block; }

.profile-orders__item.active .profile-orders__row { background: #FAF9FF; }

.profile-orders__item.active .profile-orders__col-btn { background: #FCEDEA; border-color: #FCEDEA; }

.profile-orders__item.active .profile-orders__col-btn:before { display: none; }

.profile-orders__item.active .profile-orders__col-btn:after { background: #D05278; }

.profile-orders__content { display: none; }

.profile-orders__content ul li { border-top: 1px solid #EEEEEE; font-size: 14px; line-height: 170%; justify-content: space-between; display: flex; align-items: center; color: #666666; padding: 8px 30px; background-color: #fff; }

.profile-orders__content ul li:nth-of-type(even) { background-color: #FAF9FF; }

.profile-orders__content ul li:last-of-type { font-family: 'Tenor Sans'; font-size: 20px; line-height: 120%; text-transform: capitalize; color: #222222; padding: 26px 29px; padding-right: 36px; }

.profile-orders__content ul li:last-of-type span { font-size: 16px; line-height: 170%; font-family: 'Lato'; color: #666666; text-transform: initial; }

.profile-orders__col-mob { display: none; }

.links-page { padding-top: 130px; }

.links-page li { margin-bottom: 10px; }

.main-block { min-height: 100vh; display: flex; align-items: center; justify-content: flex-start; width: 100%; height: 100%; padding-top: 110px; padding-bottom: 90px; }

.main-block .wrapper { width: 100%; }

@media screen and (min-width: 1600px) { .main-block { padding-top: 180px; padding-bottom: 180px; }
  .main-text { margin-bottom: 25px; margin-top: 0px; } }

@media screen and (max-width: 1400px) { .products-item__img { height: 435px; }
  .top-categories__item { height: 600px; }
  .info-blocks__item-img-overlay span { margin-top: 0; } }

@media screen and (max-width: 1279px) { .trending, .discount { padding-top: 110px; padding-bottom: 110px; }
  .main-logos { padding-bottom: 110px; }
  .advantages, .top-categories, .arrivals, .latest-news, .testimonials, .all-categories, .faq, .post, .shop, .product, .cart, .wishlist, .login, .error-page { padding-top: 110px; }
  .subscribe, .insta-photos, .blog { margin-top: 110px; }
  .promo-video { padding-top: 200px; margin-bottom: 110px; }
  .contacts-info { padding-top: 200px; }
  .detail-block_margin { padding-bottom: 110px; }
  .post { padding-bottom: 0; }
  .cart-bottom { margin-bottom: 0; }
  .checkout { padding-top: 170px; padding-bottom: 0; }
  .profile { padding-top: 110px; padding-bottom: 0; } }

@media screen and (max-width: 1200px) { .main-block__content { max-width: 685px; }
  .main-logos img { margin: 0 25px; max-width: 110px; }
  .discount-info { margin-right: 0; }
  .top-categories__item { height: 500px; }
  .info-blocks__item-text { padding: 186px 40px; }
  .subscribe-form { padding: 0 40px; }
  .insta-photo__hover i { font-size: 50px; }
  .insta-photos { margin-top: 110px; flex-wrap: wrap; }
  .insta-photo { width: 33.3%; margin-top: 0; height: 250px; }
  .detail-block__item { padding: 25px 15px 26px 15px; }
  .discount-about .discount-info { margin-right: 0; }
  .testimonials-slider { width: 100%; max-width: 100%; }
  .insta-photos_contacts { margin-top: 0; }
  .discount-contacts .discount-info { margin-right: 0; }
  .post-content { padding: 60px 40px 0; }
  .post-bottom, .post-comments { padding-left: 40px; padding-right: 40px; }
  .post .discount .discount-info { max-width: 460px; margin-right: 0; }
  .shop-aside { width: 210px; }
  .shop-main { width: calc(100% - 240px); }
  .product-slider { width: 480px; }
  .product-info { width: calc(100% - 520px); }
  .product-detail__form { width: 360px; padding: 50px 30px 50px; }
  .product-detail__items { width: calc(100% - 390px); }
  .cart-bottom__total { width: 430px; }
  .cart-bottom__promo { width: calc(100% - 450px); }
  .checkout-info { width: 390px; }
  .checkout-info .cart-bottom__total { width: 100%; }
  .checkout-form { width: calc(100% - 410px); }
  .checkout-order__item-info h6, .checkout-order__item-info .title6 { font-size: 16px; }
  .checkout-order__item-price { font-size: 13px; }
  .cart-table__img { min-width: 110px; }
  .error-page__info { max-width: 420px; margin-right: 65px; margin-top: 0; }
  .error-page__title span { display: block; }
  .profile-aside__subscribe { padding: 20px 20px 28px; }
  .profile-aside { width: 240px; }
  .profile-main { width: calc(100% - 260px); }
  .profile-aside__discount { padding: 90px 20px 20px; }
  .profile-aside__viewed-item-info { width: 60%; }
  .profile-aside__viewed-item-title { font-size: 18px; line-height: 125%; }
  .profile-aside__subscribe h3 { margin-bottom: 20px; }
  .profile-orders__item .profile-orders__col:after { display: none; }
  .profile-orders__item-addr, .profile-orders__item-date { font-size: 12px; }
  .shop-main__items .products-item__img { height: 315px; } }

@media screen and (max-width: 991px) { .wrapper { padding: 0 20px 0 20px; }
  p { font-size: 15px; }
  h2 { font-size: 51px; }
  .main-text { font-size: 83px; }
  .main-block__content { max-width: 575px; }
  .trending-tabs li { margin-bottom: 15px; }
  .trending { padding-top: 110px; padding-bottom: 110px; }
  .main-block { position: relative; z-index: 1; }
  .main-block p { color: #000; }
  .main-logos { padding-bottom: 110px; }
  .main-logos img { max-width: 85px; }
  .discount-info { max-width: 380px; }
  .discount-info .saint-text { font-size: 50px; }
  .discount { padding: 110px 0; }
  .advantages { padding-top: 110px; }
  .advantages-items { margin-left: -30px; margin-right: -30px; }
  .advantages-item { margin: 0 30px; width: calc(33.3% - 60px); }
  .top-categories { padding-top: 110px; }
  .top-categories__items { margin-left: -10px; margin-right: -10px; }
  .top-categories__item { width: calc(33.3% - 20px); margin: 0 10px; height: 355px; }
  .info-blocks__item-text, .info-blocks__item-reverse .info-blocks__item-text { padding: 50px 0 40px; width: 100%; }
  .info-blocks__item-img { position: relative; width: 100%; }
  .info-blocks__item .wrapper { flex-direction: column; padding-top: 40px; }
  .info-blocks__item { background-image: none !important; }
  .info-blocks__item-text h2, .info-blocks__item-text p { max-width: 100%; }
  .info-blocks { padding-top: 40px; }
  .arrivals { padding-top: 110px; }
  .arrivals .trending-top { padding-left: 0; margin-bottom: 40px; }
  .latest-news { padding-top: 110px; }
  .latest-news .trending-top { padding-left: 0; margin-bottom: 40px; }
  .blog-item__img { height: 220px; }
  .subscribe { margin-top: 110px; }
  .subscribe-form form { width: calc(100% - 100px); }
  .subscribe-form { padding: 20px 40px 0; }
  h3 { font-size: 35px; line-height: 40px; }
  .subscribe-form .box-field__row { margin-top: 20px; }
  .subscribe-form__img { width: 350px; }
  .subscribe-form form { width: 100%; }
  .insta-photos { flex-wrap: wrap; margin-top: 110px; }
  .subscribe-form form { padding-bottom: 20px; }
  .detail-block__item-icon { width: 55px; height: 45px; margin-right: 5px; }
  .detail-block__item-icon i { font-size: 34px; }
  .detail-block__item-info { margin-top: 0; font-size: 14px; line-height: 16px; }
  .detail-block__item-info h6 { font-size: 16px; }
  .detail-block__item { padding: 15px; margin: 0 6px; width: calc(33.3% - 12px); }
  .detail-block__items { margin-left: -6px; margin-right: -6px; margin-bottom: -35px; }
  .promo-video { padding-top: 110px; margin-bottom: 110px; }
  .promo-video__nums { margin-top: 110px; }
  .promo-video__num span { font-size: 44px; margin-bottom: 30px; }
  .testimonials { padding-top: 110px; margin-bottom: 0px; }
  .testimonials-slider { padding: 0 65px; }
  .testimonials .slick-arrow { left: 0; }
  .testimonials .slick-arrow.slick-next { left: auto; right: 0; }
  .insta-photos_contacts { margin-top: 0; }
  .contacts-info { padding-top: 110px; }
  .all-categories { padding-top: 110px; padding-bottom: 0; margin-bottom: -30px; }
  .detail-block_margin { padding-bottom: 110px; }
  .faq { padding-top: 110px; }
  .faq-item__content { padding-left: 70px; padding-right: 70px; }
  .blog { margin-top: 110px; }
  .post { padding-top: 110px; padding-bottom: 0; }
  .post-list li:last-of-type { margin-top: 50px; }
  .post .discount { padding: 110px 0; }
  .post .discount .discount-info { max-width: 400px; }
  .post-bottom__info { flex-direction: column; align-items: flex-start; }
  .post-bottom__tags { margin-bottom: 15px; }
  .post-bottom__tags span, .contacts-info__social span { width: 50px; }
  .post-bottom, .post-comments, .post-content { padding-left: 15px; padding-right: 15px; }
  .shop { padding-top: 110px; }
  .shop-main__filter { flex-wrap: wrap; }
  .shop-main__select { margin-top: 20px; width: 100%; }
  .shop-main__select .jq-selectbox__select { width: 100%; }
  .shop-main__checkboxes { width: 100%; justify-content: space-between; }
  .shop-main__checkboxes .checkbox-box { margin-right: 0; width: calc(50% - 10px); }
  .shop-main__items .products-item { width: calc(50% - 30px); }
  .product { padding-top: 110px; }
  .product-content { flex-direction: column; }
  .product-slider { width: 100%; }
  .product-info { width: 100%; margin-top: 40px; }
  .product-reviews { flex-direction: column; }
  .product-detail__items { width: 100%; }
  .product-detail__form { width: 100%; margin-top: 40px; }
  .product-detail__items .blog-item__link { margin-top: 40px; }
  .cart-bottom { flex-direction: column; margin-bottom: 0; }
  .cart-bottom__promo, .cart-bottom__total { width: 100%; }
  .cart-bottom__total { margin-top: 40px; }
  .cart-bottom__promo p { max-width: 100%; }
  .cart-bottom__promo .contacts-info__social span { width: 100%; }
  .cart-table { overflow-x: scroll; margin-left: -40px; margin-right: -40px; width: calc(100% + 80px); }
  .cart-table__box { min-width: 900px; }
  .cart { padding-top: 110px; }
  .wishlist-buttons { margin-left: 0; }
  .wishlist { padding-top: 110px; }
  .checkout { padding-top: 110px; padding-bottom: 0; }
  .checkout-form, .checkout-info { width: 100%; }
  .checkout-info { margin-top: 40px; padding-top: 0; }
  .checkout-content { flex-wrap: wrap; }
  .checkout-buttons { margin-top: 40px; }
  .login { padding-top: 110px; }
  .error-page { padding-top: 110px; }
  .error-page__content { flex-direction: column; }
  .error-page__info { max-width: 100%; margin-right: 0; margin-bottom: 20px; }
  .error-page__title span { margin-right: 0; }
  .error-page__img { text-align: center; }
  .profile-content { flex-direction: column-reverse; }
  .profile-main, .profile-aside { width: 100%; }
  .profile { padding-top: 110px; padding-bottom: 0; }
  .profile-orders__row-head .profile-orders__col:nth-of-type(3), .profile-orders__row-head .profile-orders__col:nth-of-type(2) { padding-left: 30px; }
  .profile-aside { margin-top: 40px; }
  .profile-aside__subscribe img { width: 140px; }
  .profile-aside__discount-title { max-width: 230px; margin-left: auto; }
  .products-item__name, .blog-item__title { font-size: 22px; }
  h4 { font-size: 30px; }
  .nav-tab-list li a { padding: 6px 25px; }
  .products-item { width: calc(50% - 30px); }
  .arrivals { padding-top: 40px; }
  .detail-block { padding-top: 180px; }
  .contacts-info__social span { width: 100%; }
  .cart-table__box { min-width: 790px; }
  .cart-table__col:first-of-type { width: 48%; }
  .wishlist .cart-table__col:first-of-type { width: 48%; }
  .cart-table__col:nth-of-type(3) { padding-left: 0; }
  .cart-table__col { width: 15%; }
  .wishlist .cart-table__col { width: 19%; }
  .checkout-step3 .checkout-info { padding-top: 0px; } }

@media screen and (max-width: 767px) { .wishlist .cart-table__col { width: 16%; }
  .scroll-off body { overflow: hidden; }
  .scroll-off .content { position: relative; }
  .scroll-off .content:before { position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: ''; background-color: rgba(34, 34, 34, 0.15); z-index: 10; }
  .wrapper { padding-left: 15px; padding-right: 15px; }
  .main-block__decor { display: none; }
  .main-block .saint-text { font-size: 45px; }
  .main-text { font-size: 58px; }
  .main-block p { font-size: 16px; }
  .main-block .btn { margin-top: 30px; }
  .trending-tabs li { margin-left: 15px; margin-right: 15px; width: 100%; text-align: center; margin-bottom: -1px; }
  .products-items { margin-left: -15px; margin-right: -15px; padding: 0 15px; }
  .slick-arrow { top: 40.5%; left: 30px; z-index: 1; }
  .slick-arrow.slick-next { right: 30px; }
  .products-item__hover-options i { width: 45px; height: 45px; }
  .products-item__hover .icon-search { font-size: 50px; }
  .products-item__name { font-size: 20px; margin-bottom: 0px; }
  .products-item__cost { font-size: 16px; }
  .saint-text { font-size: 40px; }
  h2 { font-size: 40px; line-height: 42px; }
  .trending { padding-top: 60px; padding-bottom: 60px; }
  .trending-top p, .products-item__info { margin-top: 20px; }
  .trending-top { margin-bottom: 20px; }
  .trending-tabs .tabs { margin-bottom: 20px; }
  .main-logos { flex-wrap: wrap; padding-bottom: 35px; }
  .main-logos img { max-width: 100px; margin-bottom: 25px; }
  .discount { padding: 60px 0; }
  .discount-info .saint-text { font-size: 45px; padding-right: 20px; }
  .discount-info p { font-size: 16px; }
  .discount-info .btn { margin-top: 30px; }
  .discount-info { max-width: 290px; text-align: right; }
  .post .discount .discount-info { text-align: left; }
  .discount-contacts .discount-info { text-align: left; }
  .advantages { padding-top: 60px; }
  .advantages-items { flex-wrap: wrap; margin-left: 0; margin-right: 0; }
  .advantages-item { margin-left: 0; margin-right: 0; margin-bottom: 35px; width: 100%; }
  .advantages-item:last-of-type { margin-bottom: 0; }
  .advantages-item__icon { margin-bottom: 25px; }
  h4 { font-size: 28px; }
  .top-categories { padding-top: 60px; padding-left: 15px; padding-right: 15px; }
  .top-categories__text { padding-left: 0; margin-bottom: 20px; }
  .top-categories__item { width: calc(50% - 20px); }
  .info-blocks__item-img { height: 335px; }
  .info-blocks__item-descr, .info-blocks__item-text p { margin-top: 15px; }
  .info-blocks__item-text .btn { margin-top: 30px; }
  .info-blocks__item-img-overlay span { font-size: 28px; }
  .info-blocks__item-img-play { width: 85px; height: 85px; }
  .arrivals { padding-top: 60px; }
  .arrivals .trending-top { margin-bottom: 20px; }
  .latest-news { padding-top: 60px; }
  .latest-news .trending-top { margin-bottom: 20px; }
  .blog-items, .blog-item { margin-left: 0; margin-right: 0; }
  .blog-item { width: 100%; margin-bottom: 30px; }
  .blog-item:last-of-type { margin-bottom: 0; }
  .latest-news__btn { margin-top: 40px; }
  .blog-item__title { margin-top: 20px; margin-bottom: 15px; }
  .blog-item p { width: 100%; margin-bottom: 20px; }
  .subscribe { margin-top: 60px; margin-left: -15px; margin-right: -15px; }
  .subscribe-form { padding: 55px 15px 0px; flex-direction: column-reverse; }
  .subscribe-form .box-field__row { flex-direction: column; }
  .subscribe-form .box-field__row .box-field { margin-bottom: 10px; }
  .subscribe-form .box-field__row .box-field, .subscribe-form .box-field__row .btn { width: 100%; }
  .subscribe-form form { margin-top: 0; }
  .box-field .form-control { padding: 18px 15px; }
  .subscribe-form__img { width: 100%; text-align: center; margin-right: 0; margin-bottom: -3px; }
  .subscribe-form__img:after { left: 34%; }
  .subscribe-form form { padding-bottom: 0; }
  .insta-photos { margin-top: 60px; }
  .insta-photo { width: 50%; height: 155px; }
  .subscribe-form__img img { position: relative; min-height: 1px; }
  .detail-block { padding-top: 130px; padding-bottom: 60px; }
  h1 { font-size: 55px; line-height: 58px; }
  .detail-block__items { margin-bottom: 0; }
  .bread-crumbs li { font-size: 14px; }
  .detail-block__items { margin-left: 0; margin-right: 0; margin-top: 30px; flex-wrap: wrap; flex-direction: column; }
  .detail-block__item { margin: 0 auto; margin-bottom: 15px; width: 100%; max-width: 320px; justify-content: flex-start; }
  .detail-block__item:last-of-type { margin-bottom: 0; }
  .detail-block__item-icon { margin-right: 25px; }
  .detail-block__item-info { font-size: 16px; line-height: 22px; }
  .detail-block__item-info h6 { font-size: 18px; }
  .promo-video__decor { display: none; }
  .promo-video { padding: 60px 0; margin-bottom: 0; }
  .promo-video__block { height: 355px; }
  .promo-video__nums { margin-top: 10px; padding: 0; flex-wrap: wrap; justify-content: space-between; }
  .promo-video__num { width: 45%; margin-top: 50px; }
  .testimonials { padding-top: 60px; }
  .testimonials-slide { display: flex !important; flex-direction: column-reverse; }
  .testimonials-author { max-width: 160px; margin: auto; margin-top: 0; margin-bottom: 30px; }
  .testimonials-slider { padding: 0; }
  .insta-photos_contacts { margin-top: 0; }
  .contacts-info { padding-top: 60px; padding-bottom: 60px; }
  .contacts-info__content { flex-direction: column; align-items: flex-start; }
  .contacts-info__text { width: 100%; margin-bottom: 20px; }
  .contacts-info__text h4 { margin-bottom: 20px; }
  .contacts-info__social { margin-top: 0; }
  .discount-contacts .discount-info { max-width: 400px; }
  .all-categories { padding-top: 60px; padding-bottom: 0; margin-bottom: 0px; }
  .all-categories .top-categories__item { margin-bottom: 20px; }
  .faq { padding-top: 60px; }
  .faq-more { margin-top: 60px; }
  .box-field__row-search { flex-direction: column; }
  .box-field__row-search .box-field { width: 100%; margin-bottom: 10px; }
  .btn-icon { justify-content: center; }
  .faq-search { margin-bottom: 40px; }
  .faq-item__head { font-size: 16px; padding-bottom: 20px; }
  .faq-item { margin-bottom: 30px; }
  .faq-item__head-num { margin-right: 15px; min-width: 40px; }
  .faq-item__content { padding: 20px 0; padding-top: 0; margin-top: 20px; }
  .blog { margin-top: 60px; }
  .blog-items { margin-bottom: 60px; }
  .paging-list__link { min-width: 35px; height: 50px; }
  .paging-list__link i { font-size: 15px; }
  .post-bottom, .post-comments, .post-content { padding-left: 0; padding-right: 0; }
  .bread-crumbs { flex-wrap: wrap; }
  .post { padding-top: 60px; }
  .post-top p { margin-top: 20px; margin-bottom: 20px; font-size: 18px; }
  .post-top__info { padding: 20px 0 20px; flex-wrap: wrap; }
  .post-top__info li { margin-left: 0; width: 50%; line-height: 14px; margin-bottom: 10px; }
  .post-top__info li:nth-of-type(even) { text-align: right; justify-content: flex-end; }
  .post-top__info li i { width: 20px; }
  .post-content { padding-top: 20px; }
  .post-content h6 { margin-top: 20px; padding-top: 20px; margin-bottom: 0; }
  .post-content blockquote { margin-top: 20px; }
  blockquote, .blockquote { padding: 20px; margin-bottom: 20px; font-size: 18px; line-height: 134%; }
  .blockquote-author { font-size: 20px; margin-top: 15px; }
  .post-list li { margin-top: 20px; width: 100%; }
  .post-list { padding-top: 0; }
  .post-list li:last-of-type { margin-top: 20px; }
  .post .discount { padding: 60px 0; margin-bottom: 60px; margin-top: 60px; }
  .discount li { font-size: 14px; line-height: 150%; }
  .post .discount { position: relative; z-index: 1; }
  .post .discount:after { width: 100%; height: 100%; z-index: -1; content: ''; background-color: rgba(255, 255, 255, 0.5); position: absolute; left: 0; top: 0; }
  .post-bottom__tags { margin-top: 0; flex-wrap: wrap; margin-bottom: 10px; }
  .post-bottom__tags span { margin-bottom: 10px; }
  .post-bottom__tags ul { flex-wrap: wrap; }
  .post-bottom__tags ul li { margin-right: 10px; margin-bottom: 10px; }
  .post-bottom__nav a { font-size: 12px; }
  .post-bottom__nav a i { margin-right: 10px; font-size: 14px; }
  .post-bottom__nav a:last-of-type i { margin-right: 0; margin-left: 10px; }
  .post-comments { margin-top: 60px; }
  .post-comments h3 { margin-bottom: 10px; }
  .post-comment { padding: 15px; margin-bottom: 15px; }
  .post-comment__head { flex-wrap: wrap; }
  .post-comment__author { width: 100%; }
  .post-comment__author img { margin-right: 15px; }
  .post-comment__name { margin-right: 0; }
  .post-comment__date { margin-left: auto; width: 150px; text-align: right; }
  .post-comment__reply { margin-top: 10px; text-align: right; width: 100%; justify-content: flex-end; }
  .post-comment__content { margin-top: 15px; }
  .post-comment { margin-left: 0; }
  .post-comment__form { padding: 55px 15px; margin-top: 60px; margin-left: -15px; margin-right: -15px; }
  .post-comment__form .btn { width: 100%; }
  .post-comments h3 { line-height: 40px; }
  .post-comment__form p { margin-bottom: 20px; }
  .post-comment__form .box-field__row { margin-left: 0; margin-right: 0; flex-wrap: wrap; }
  .post-comment__form .box-field__row .box-field { margin-left: 0; margin-right: 0; width: 100%; }
  .shop { padding-top: 60px; }
  .shop-content { flex-wrap: wrap; }
  .shop-aside, .shop-main { width: 100%; }
  .shop-aside .box-field__search { margin-bottom: 20px; }
  .shop-aside__item-title { margin-bottom: 10px; }
  .shop-aside__item ul li a { padding: 5px 0; }
  .shop-aside__item { margin-bottom: 20px; }
  .shop-aside__item .range-slider { margin-bottom: 90px; }
  .shop-aside__item-product:first-of-type { margin-top: 20px; }
  .shop-aside__item-product { margin-bottom: 20px; }
  .shop-aside__item-product:last-of-type { margin-bottom: 0; }
  .shop-main { margin-top: 40px; }
  .shop-main__filter { margin-bottom: 20px; }
  .shop-main__items .products-item { width: calc(50% - 30px); }
  .shop-main__items .products-item__info { margin-top: 10px; }
  .shop-main__items .products-item__img { height: 440px; }
  .shop-decor { display: none; }
  .discount li { font-size: 16px; }
  .post-comment__name:after { display: none; }
  .product { padding-top: 60px; }
  .product-stock { margin-top: 10px; }
  .product-price { margin-top: 10px; margin-bottom: 20px; font-size: 25px; }
  .product-price span { font-size: 16px; }
  .product-info .contacts-info__social { margin-top: 20px; flex-direction: column; align-items: flex-start; }
  .product-info .contacts-info__social span { display: block; margin-right: 0; width: 100%; margin-bottom: 10px; }
  .product-options { margin-top: 25px; padding-top: 20px; flex-direction: column; }
  .product-info__color { margin-bottom: 20px; }
  .product-info__color span { margin-bottom: 10px; }
  .product-info__quantity-title { margin-bottom: 10px; }
  .product-buttons { margin-top: 40px; flex-wrap: wrap; }
  .product-buttons .btn { width: 100%; }
  .product-buttons .btn:first-of-type { margin-bottom: 10px; }
  .review-item { padding: 15px; margin-bottom: 15px; }
  .review-item__head { flex-wrap: wrap; }
  .review-item__author { width: 100%; }
  .review-item__name { margin-right: 0; }
  .review-item__author img { margin-right: 15px; }
  .review-item__date { margin-left: auto; width: 150px; text-align: right; }
  .review-item__rating { margin-top: 0; text-align: right; width: 100%; justify-content: flex-end; display: flex; }
  .review-item__content { margin-top: 10px; }
  .review-item__name:after, .review-item__date:after { display: none; }
  .product-detail__items .blog-item__link { margin-top: 20px; }
  .product-detail__form { margin-top: 30px; width: calc(100% + 30px); }
  .cart { padding-top: 60px; }
  .cart-table { margin-left: -15px; margin-right: -15px; width: calc(100% + 30px); }
  .cart-bottom__total { padding: 15px; margin-top: 30px; }
  .cart-bottom__promo .contacts-info__social { margin-top: 20px; }
  .cart-bottom__promo-form .box-field__row { flex-direction: column; }
  .cart-bottom__promo-form .box-field__row .box-field { width: 100%; margin-bottom: 10px; }
  .cart-bottom__promo-form { margin-bottom: 30px; }
  .cart-bottom { margin-top: 30px; }
  .cart-table__row { margin-bottom: 10px; padding: 10px 0; }
  .cart-table__row:last-of-type { margin-bottom: 0; }
  .cart-table__row-head { font-size: 18px; }
  .cart-table__col:first-of-type { padding-left: 15px; }
  .wishlist { padding-top: 60px; }
  .wishlist-buttons { margin-top: 30px; }
  .wishlist-buttons .btn { width: 100%; text-align: center; margin-right: 0; margin-bottom: 10px; }
  .wishlist-buttons .btn:last-of-type { margin-bottom: 0; }
  .checkout { padding-top: 60px; }
  .checkout-form__item .box-field__row { flex-direction: column; }
  .checkout-form__item .box-field__row .box-field { width: 100%; margin-bottom: 10px; }
  .checkout-form__item .jq-selectbox__select { padding: 17px 15px; }
  .checkout-form__item .jq-selectbox__trigger { right: 3px; }
  .checkout-form__item .checkbox-box { margin-top: 20px; }
  .checkout-buttons { flex-wrap: wrap; }
  .checkout-buttons .btn { width: 100%; text-align: center; margin-right: 0; margin-bottom: 10px; }
  .checkout-buttons .btn:last-of-type { margin-bottom: 0; }
  .cart-bottom__total-delivery span.cart-bottom__total-delivery-date { font-size: 14px; }
  .checkout-order__item-img { min-width: 95px; width: 95px; height: auto; }
  .checkout-order__item { align-items: center; }
  .checkout-payment__item { padding: 20px 10px; }
  .checkout-payment__item-content { padding-top: 13px; }
  .checkout-payment .checkout-buttons { margin-top: 40px; }
  .checkout-payment__item-content .box-field__row { margin-left: 0; margin-right: 0; flex-wrap: wrap; margin-top: 10px; }
  .checkout-payment__item-content .box-field__row .box-field { margin-left: 0; margin-right: 0; width: 100%; margin-bottom: 10px; }
  .checkout-payment__item.active { padding-bottom: 10px; }
  .radio-box__info-content { bottom: auto; position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%); }
  .radio-box__info-content:after { display: none; }
  .checkout-purchase__list li span { width: 50%; }
  .checkout-step3 .checkout-info { padding-top: 0; }
  .checkout-purchase__link { margin-top: 20px; }
  .checkout-purchase__list { margin-top: 20px; }
  .checkout-purchase__list li { padding: 7px 15px; align-items: center; line-height: 120%; }
  .checkout-step3 .cart-bottom__total .btn { margin-top: 60px; }
  .login { padding-top: 60px; }
  .login-form { padding: 35px 15px; }
  .login-form__bottom { flex-direction: column; margin-top: 10px; }
  .login-form .checkbox-box { margin-top: 20px; margin-bottom: 20px; }
  .login-form h3, .login-form__social { margin-bottom: 20px; }
  .registration .box-field__row { margin-left: 0; margin-right: 0; }
  .registration .box-field__row .box-field { margin-left: 0; margin-right: 0; width: 100%; }
  .registration .box-field__row span { margin-top: 5px; margin-left: 0; }
  .registration .login-form .checkbox-box { margin-top: 10px; margin-bottom: 20px; }
  .error-page { padding-top: 60px; }
  .error-page .box-field__row-search { flex-direction: row; }
  .error-page__title span { font-size: 100px; }
  .error-page__title { font-size: 30px; }
  .error-page__subtitle { font-size: 14px; }
  .profile { padding-top: 60px; }
  .profile .nav-tab-list { flex-wrap: wrap; margin-bottom: 10px; }
  .profile .nav-tab-list li { margin-right: 0; width: 100%; margin-bottom: 10px; }
  .profile-orders__row-head { display: none; }
  .profile-aside { margin-top: 20px; }
  .profile-aside__subscribe { padding: 15px; }
  .profile-aside__viewed { margin-top: 20px; }
  .profile-aside__viewed-item { margin-top: 20px; }
  .profile-aside__discount { padding: 50px 15px 15px; margin-top: 20px; }
  .profile-orders__item .profile-orders__row { padding: 15px 0; flex-direction: column; align-items: flex-start; }
  .profile-orders__content ul li, .profile-orders__content ul li:last-of-type { padding: 8px 15px; }
  .profile-orders__content ul li:last-of-type { flex-direction: column; align-items: flex-start; }
  .profile-orders__col:nth-of-type(1), .profile-orders__col:nth-of-type(2), .profile-orders__col:nth-of-type(3), .profile-orders__col:nth-of-type(4) { width: 100%; text-align: left; padding: 5px 15px; }
  .profile-orders__col:nth-of-type(1) br, .profile-orders__col:nth-of-type(2) br, .profile-orders__col:nth-of-type(3) br, .profile-orders__col:nth-of-type(4) br { display: none; }
  .profile-orders__item .profile-orders__col:nth-of-type(3) { padding: 5px 15px; }
  .profile-orders__item-addr, .profile-orders__item-date { font-size: 14px; line-height: 120%; }
  .profile-orders__item .profile-orders__col-btn { right: 15px; }
  .profile-orders__col-mob { display: block; font-size: 12px; font-weight: 700; text-transform: uppercase; margin: 5px 0; }
  .products-item { width: calc(100% - 30px); }
  .info-blocks__item-img { height: 370px; }
  .info-blocks__item-img-overlay span { margin-bottom: 20px; }
  .arrivals .trending-top { padding-left: 15px; padding-right: 15px; }
  .insta-photo { height: 285px; }
  .testimonials-slide p { font-size: 18px; }
  .faq-item__content { padding-left: 55px; }
  .product-slider__main img { height: 630px; }
  .product-slider__nav-item { height: 152px; }
  h5, .title5 { line-height: 115%; }
  .radio-box__info:hover .radio-box__info-content { position: absolute; top: auto; bottom: 30px; transform: translateX(-50%); }
  .radio-box__info { position: initial; }
  .radio-box__info:hover .radio-box__info-content { opacity: 0; visibility: hidden; display: none; }
  .radio-box__info-content.active { opacity: 1 !important; visibility: visible !important; display: block !important; } }

@media screen and (max-width: 480px) { .main-block { background-image: url(../img/main-bg-sm.jpg); }
  .main-block.load-bg { background-image: none; }
  .detail-block { background-image: url(../img/detail-main-bg-sm.jpg); }
  .insta-photo { height: 155px; }
  .top-categories__items { margin-left: 0; margin-right: 0; flex-wrap: wrap; }
  .top-categories__item { margin-left: 0; margin-right: 0; margin-bottom: 15px; width: 100%; height: 345px; }
  .top-categories__item:last-of-type { margin-bottom: 0; }
  .all-categories .top-categories__item { margin-bottom: 15px; }
  .shop-main__items { margin-left: 0; margin-right: 0; }
  .shop-main__items .products-item { margin-left: 0; margin-right: 0; width: 100%; margin-bottom: 20px; }
  .product-slider__main img { height: 350px; }
  .product-slider__nav-item { height: 80px; } }
